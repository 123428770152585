@font-face {
  font-family: revicons;
  fallback: fallback;
  src: url("revicons.93d74edb.woff") format("woff"), url("revicons.6716ab81.ttf") format("ttf"), url("revicons.80a7204d.eot") format("ttf");
}

.react-multi-carousel-list {
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.react-multi-carousel-track {
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform, transition;
  flex-direction: row;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
}

.react-multiple-carousel__arrow {
  z-index: 1000;
  opacity: 1;
  cursor: pointer;
  background: #00000080;
  border: 0;
  border-radius: 35px;
  outline: 0;
  min-width: 43px;
  min-height: 43px;
  transition: all .5s;
  position: absolute;
}

.react-multiple-carousel__arrow:hover {
  background: #000c;
}

.react-multiple-carousel__arrow:before {
  color: #fff;
  text-align: center;
  z-index: 2;
  font-family: revicons;
  font-size: 20px;
  display: block;
  position: relative;
}

.react-multiple-carousel__arrow:disabled {
  cursor: default;
  background: #00000080;
}

.react-multiple-carousel__arrow--left {
  left: calc(4% + 1px);
}

.react-multiple-carousel__arrow--left:before {
  content: "";
}

.react-multiple-carousel__arrow--right {
  right: calc(4% + 1px);
}

.react-multiple-carousel__arrow--right:before {
  content: "";
}

.react-multi-carousel-dot-list {
  text-align: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.react-multi-carousel-dot button {
  opacity: 1;
  box-shadow: none;
  cursor: pointer;
  border: 2px solid gray;
  border-radius: 50%;
  outline: 0;
  width: 12px;
  height: 12px;
  margin: 0 6px 0 0;
  padding: 0;
  transition: background .5s;
  display: inline-block;
}

.react-multi-carousel-dot button:hover:active, .react-multi-carousel-dot--active button {
  background: #080808;
}

.react-multi-carousel-item {
  transform-style: preserve-3d;
  backface-visibility: hidden;
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .react-multi-carousel-item {
    flex-shrink: 0 !important;
  }

  .react-multi-carousel-track {
    overflow: visible !important;
  }
}

[dir="rtl"].react-multi-carousel-list {
  direction: rtl;
}

.rtl.react-multiple-carousel__arrow--right {
  left: calc(4% + 1px);
  right: auto;
}

.rtl.react-multiple-carousel__arrow--right:before {
  content: "";
}

.rtl.react-multiple-carousel__arrow--left {
  left: auto;
  right: calc(4% + 1px);
}

.rtl.react-multiple-carousel__arrow--left:before {
  content: "";
}

.szh-menu {
  box-sizing: border-box;
  z-index: 100;
  background-color: #fff;
  border: 1px solid #0000001a;
  width: max-content;
  margin: 0;
  padding: 0;
  list-style: none;
}

.szh-menu:focus {
  outline: none;
}

.szh-menu__arrow {
  box-sizing: border-box;
  z-index: -1;
  background-color: #fff;
  border: 1px solid #0000;
  border-color: #0000001a #0000 #0000 #0000001a;
  width: .75rem;
  height: .75rem;
}

.szh-menu__arrow--dir-left {
  right: -.375rem;
  transform: translateY(-50%)rotate(135deg);
}

.szh-menu__arrow--dir-right {
  left: -.375rem;
  transform: translateY(-50%)rotate(-45deg);
}

.szh-menu__arrow--dir-top {
  bottom: -.375rem;
  transform: translateX(-50%)rotate(-135deg);
}

.szh-menu__arrow--dir-bottom {
  top: -.375rem;
  transform: translateX(-50%)rotate(45deg);
}

.szh-menu__item {
  cursor: pointer;
}

.szh-menu__item:focus {
  outline: none;
}

.szh-menu__item--hover {
  background-color: #ebebeb;
}

.szh-menu__item--focusable {
  cursor: default;
  background-color: inherit;
}

.szh-menu__item--disabled {
  cursor: default;
  color: #aaa;
}

.szh-menu__group {
  box-sizing: border-box;
}

.szh-menu__radio-group {
  margin: 0;
  padding: 0;
  list-style: none;
}

.szh-menu__divider {
  background-color: #0000001f;
  height: 1px;
  margin: .5rem 0;
}

.szh-menu-button {
  box-sizing: border-box;
}

.szh-menu {
  -webkit-user-select: none;
  user-select: none;
  color: #212529;
  border: none;
  border-radius: .25rem;
  min-width: 10rem;
  padding: .5rem 0;
  box-shadow: 0 3px 7px #0002, 0 .6px 2px #0000001a;
}

.szh-menu__item {
  align-items: center;
  padding: .375rem 1.5rem;
  display: flex;
  position: relative;
}

.szh-menu-container--itemTransition .szh-menu__item {
  transition-property: background-color, color;
  transition-duration: .15s;
  transition-timing-function: ease-in-out;
}

.szh-menu__item--type-radio {
  padding-left: 2.2rem;
}

.szh-menu__item--type-radio:before {
  content: "○";
  font-size: .8rem;
  position: absolute;
  top: .55rem;
  left: .8rem;
}

.szh-menu__item--type-radio.szh-menu__item--checked:before {
  content: "●";
}

.szh-menu__item--type-checkbox {
  padding-left: 2.2rem;
}

.szh-menu__item--type-checkbox:before {
  position: absolute;
  left: .8rem;
}

.szh-menu__item--type-checkbox.szh-menu__item--checked:before {
  content: "✔";
}

.szh-menu__submenu > .szh-menu__item {
  padding-right: 2.5rem;
}

.szh-menu__submenu > .szh-menu__item:after {
  content: "❯";
  position: absolute;
  right: 1rem;
}

.szh-menu__header {
  color: #888;
  text-transform: uppercase;
  padding: .2rem 1.5rem;
  font-size: .8rem;
}

@keyframes szh-menu-show-zoom {
  from {
    opacity: .1;
    transform: scale(.95);
  }
}

@keyframes szh-menu-hide-zoom {
  to {
    opacity: .1;
    transform: scale(.95);
  }
}

.szh-menu--state-opening {
  animation: .125s ease-out szh-menu-show-zoom;
}

.szh-menu--state-closing {
  animation: .125s ease-in forwards szh-menu-hide-zoom;
}

.szh-menu--dir-left.szh-menu--align-start {
  transform-origin: 100% 0;
}

.szh-menu--dir-left.szh-menu--align-center {
  transform-origin: 100%;
}

.szh-menu--dir-left.szh-menu--align-end {
  transform-origin: 100% 100%;
}

.szh-menu--dir-right.szh-menu--align-start {
  transform-origin: 0 0;
}

.szh-menu--dir-right.szh-menu--align-center {
  transform-origin: 0;
}

.szh-menu--dir-right.szh-menu--align-end, .szh-menu--dir-top.szh-menu--align-start {
  transform-origin: 0 100%;
}

.szh-menu--dir-top.szh-menu--align-center {
  transform-origin: bottom;
}

.szh-menu--dir-top.szh-menu--align-end {
  transform-origin: 100% 100%;
}

.szh-menu--dir-bottom.szh-menu--align-start {
  transform-origin: 0 0;
}

.szh-menu--dir-bottom.szh-menu--align-center {
  transform-origin: top;
}

.szh-menu--dir-bottom.szh-menu--align-end {
  transform-origin: 100% 0;
}

:root {
  --main-bg-color: #abc7a1;
  --main-text-color: #544251;
  --secondary-bg-color: #c7a1c2;
  --secondary-text-color: #1a3310;
  --ternary-bg-color: #55724b;
  --ternary-text-color: #e4fedb;
  --article-bg-color: #f4f7f2;
  --section-margins: 5vh 15vw;
  --dark-color: #3d5b59;
}

html, body, * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Roboto;
}

@font-face {
  font-family: Roboto;
  src: url("Roboto-Regular.ca197847.ttf");
}

@font-face {
  font-family: Rock3D;
  src: url("Rock3D-Regular.d15a4285.ttf");
}

@font-face {
  font-family: Frederika;
  src: url("FrederickatheGreat-Regular.8a3f82d8.ttf");
}

@font-face {
  font-family: Caveat;
  src: url("Caveat-VariableFont_wght.8880e69d.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("Montserrat-VariableFont_wght.c16ad322.ttf");
}

@font-face {
  font-family: Poppins;
  src: url("Poppins-Light.ace0ebd3.ttf");
}

@font-face {
  font-family: SpaceGrotesk;
  src: url("SpaceGrotesk-VariableFont_wght.6c67f6e5.ttf");
}

#app {
  color: var(--dark-color);
  flex-direction: column;
  width: 100%;
  display: flex;
  position: relative;
}

#app:before {
  content: "";
  opacity: .2;
  background: url("paper1.7ed31815.jpg") 0 0 / cover;
  position: absolute;
  inset: 0;
}

.page-top-menu-wrapper {
  z-index: 9999;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  height: 110px;
  padding: 20px 50px;
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  box-shadow: 0 5px 5px #00000080;
}

.inner-top-menu-wrapper {
  justify-content: space-between;
  width: 100%;
  height: 100%;
  display: flex;
}

.logo-wrapper {
  position: relative;
}

.page-logo {
  cursor: pointer;
  height: 76px;
  position: absolute;
}

.desktop-page-menu {
  align-items: center;
  height: 100%;
  display: flex;
}

.desktop-page-menu .menu-item {
  transition: all .2s;
}

.desktop-page-menu .menu-item:hover {
  opacity: .5;
}

.mobile-page-menu {
  display: none;
}

.menu-item {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  margin: 0 12px;
  font-family: SpaceGrotesk;
  font-size: 15px;
  font-weight: normal;
  display: flex;
}

.menu-item-active {
  font-weight: bold;
}

.menu-item-redirect35 {
  color: #fcb5ac;
  border: 1px solid #fcb5ac;
  border-radius: 8px;
  height: 40px;
  padding: 10px 16px;
}

.menu-item-donate {
  color: #fff;
  background: #fcb5ac;
  border-radius: 8px;
  height: 40px;
  padding: 10px 16px;
}

.language-picker-wrapper {
  align-items: center;
  margin-left: 20px;
  display: flex;
}

.mobile-language-picker-wrapper {
  text-align: center;
  align-items: center;
  height: 50px;
  margin: 0 24px 10px;
  display: flex;
}

.mobile-language-picker-wrapper > .language-item {
  flex: 1;
}

.language-picker-flag-item {
  width: 20px;
  height: 12px;
  margin: 0 6px;
}

.language-item {
  cursor: pointer;
}

.language-item.inactive {
  opacity: .5;
}

.hero-section {
  width: 100%;
  height: 55vh;
  margin-top: 110px;
  position: relative;
}

.hero-section-bg-image {
  background: url("bg1_1k.e4c3ed0a.webp") center / cover no-repeat;
  position: absolute;
  inset: 0;
}

.our-mission-title {
  color: #3d5b59;
  text-align: center;
  width: 100%;
  margin: 5vh 0;
  font-size: 40px;
}

.our-mission-content {
  text-align: justify;
  color: #3d5b59;
  font-size: 18px;
  line-height: 22px;
}

.who-we-are-page .hero-section, .what-we-do-page .hero-section, .mindlab-page .hero-section, .get-involved-page .hero-section, .donate-page .hero-section, .blog-page .hero-section {
  height: 65vh;
}

.who-we-are-page .hero-section {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

.whote-we-are-page .hero-section-bg-image {
  position: relative;
}

.who-we-are-page .hero-section-bg-image {
  background: url("allteam.70db2c9e.webp") 0 28% / cover no-repeat;
}

.what-we-do-page .hero-section-bg-image {
  background: url("pawns.a2515dbb.jpg") 0 30% / cover no-repeat;
}

.mindlab-page .hero-section-bg-image {
  background: url("https://images.unsplash.com/photo-1498243691581-b145c3f54a5a?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D") 0 28% / cover no-repeat;
}

.get-involved-page .hero-section-bg-image {
  background: url("hands2_1k.2d8c8459.webp") center / cover no-repeat;
}

.blog-page .hero-section-bg-image {
  background: url("blog.1fc7680a.jpg") center / cover no-repeat;
}

.donate-page .hero-section-bg-image {
  background: url("donate-bg.86278a0e.webp") center / cover no-repeat;
}

.hero-section img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.title-wrapper {
  font-size: 40px;
}

.hero-section .message {
  color: #fff;
  text-align: right;
  background: linear-gradient(146deg, #d2ddc0b3 0%, #d2ddc0 80%);
  border-radius: 8px;
  justify-content: flex-end;
  align-items: center;
  width: 40%;
  min-height: 15vh;
  padding: 5vh 5vw;
  font-size: 24px;
  font-style: italic;
  display: flex;
  position: absolute;
  bottom: 25%;
  right: 5%;
}

.homepage-page, .who-we-are-page, .what-we-do-page, .mindlab-page, .blog-page, .terms-and-conditions, .confidentiality-policy, .donate-page, .get-involved-page, .our-products-page {
  margin-bottom: 5vh;
  position: relative;
}

.contact-us-wrapper, .volunteer-wrapper {
  color: #000;
  background: #b99095eb;
  border-radius: 20px;
  flex-direction: column;
  padding: 3vh 5vw;
  display: flex;
}

.contact-us-wrapper .raw-content-wrapper {
  flex: 1;
}

.contact-us-wrapper .social-media-wrapper {
  max-width: 160px;
}

.volunteer-wrapper {
  color: #fff;
  background: #3d5b59b6;
}

.objectives-wrapper {
  min-height: 20vw;
}

.who-we-are-page .hero-section .message, .mindlab-page .hero-section .message, .what-we-do-page .hero-section .message, .blog-page .hero-section .message, .donate-page .hero-section .message, .get-involved-page .hero-section .message {
  background: linear-gradient(146deg, #a6a9a2ad 0%, #4e4b46 85%);
}

.get-involved-content-wrapper {
  grid-gap: 2vw;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 10vw;
  display: grid;
}

.transition-wrapper {
  margin-top: -8vh;
  position: relative;
  top: 1px;
  overflow: hidden;
}

.transition-wrapper img {
  width: 100%;
  transform: scale(1.4);
}

.transition-wrapper-bottom {
  position: relative;
  top: -1px;
  overflow: hidden;
}

.transition-wrapper-bottom img {
  width: 100%;
  transform: scale(1.2)rotate(180deg);
}

.page-title-section {
  text-align: justify;
  align-items: center;
  min-height: 30vh;
  margin: 0 10vw;
  padding: 3vh 0;
  font-family: Poppins;
  font-size: 32px;
  line-height: 32px;
  display: flex;
  position: relative;
}

.homepage-page .page-title-section {
  color: #fff;
  background: #b99095;
  margin: 0;
  padding: 3vh 10vw;
}

.page-title-section span {
  font-weight: bold;
}

.who-we-are-page .page-title-section, .what-we-do-page .page-title-section, .mindlab-page .page-title-section, .get-involved-page .page-title-section, .our-products-page .page-title-section, .ongoing-projects-page .page-title-section {
  text-align: justify;
  padding: 10vh 0;
  font-size: 20px;
  line-height: 24px;
}

.values-section {
  margin: var(--section-margins);
  position: relative;
}

.values-section .title-wrapper {
  text-align: center;
  margin-bottom: 30px;
}

.values-wrapper {
  grid-template-columns: repeat(6, 1fr);
  justify-content: center;
  align-items: center;
  gap: 50px;
  display: grid;
}

.value-item {
  flex-direction: column;
  grid-column: span 2;
  align-items: center;
  display: flex;
}

.value-item.count-5.index-3 {
  grid-column: 2 / 4;
}

.value-icon {
  background: linear-gradient(146deg, #fff 0%, #fcb5ac4d 80%);
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  display: flex;
  box-shadow: 5px 5px 10px #0000001a;
}

.value-icon img {
  object-fit: contain;
  width: 60%;
  height: 60%;
}

.value-text {
  text-align: center;
}

.value-title {
  margin: 10px 0;
  font-size: 20px;
  font-weight: bold;
}

.value-description {
  font-size: 18px;
}

.mindlab-section {
  color: #fff;
  background: url("paper1.7ed31815.jpg") 0 0 / 100%;
  border-radius: 30px 0 0;
  margin: 10vh 0 10vh 7.5vw;
}

.our-team-section, .donate-section {
  color: #fff;
  background: url("paper1.7ed31815.jpg") 0 0 / 100%;
  border-radius: 0 30px 0 0;
  margin: 10vh 7.5vw 10vh 0;
}

.our-team-section-outer-wrapper {
  background: red;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.our-team-section-content-wrapper {
  background: pink;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 1400px;
  display: grid;
}

.our-team-section .background-gradient, .donate-section .background-gradient, .mindlab-section .background-gradient {
  background: linear-gradient(329deg, #b99095 30%, #b99095a2 100%);
  border-radius: 30px 0 0;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 5vh 7.5vw;
  display: grid;
  box-shadow: 2px 2px 5px #00000080;
}

.our-team-section .background-gradient, .donate-section .background-gradient {
  background: linear-gradient(329deg, #3d5b59 30%, #3d5b59b6 100%);
  border-radius: 0 30px 0 0;
}

.our-team-section .section-bg-image, .donate-section .section-bg-image, .mindlab-section .section-bg-image {
  background: url("ourTeam.09f760b1.webp") top / cover no-repeat;
  border-radius: 0 20px;
  grid-area: 1 / 2 / 3 / 4;
  height: 50vh;
  box-shadow: 3px 3px 10px #00000080;
}

.our-team-section .section-bg-image, .donate-section .section-bg-image {
  grid-column: 1 / 3;
}

.donate-section .section-bg-image {
  background: url("hands_1k.801be37d.webp") center / cover no-repeat;
}

.mindlab-section .section-bg-image {
  background: url("mindlab-cover.3bbd1f70.jpg") center / cover no-repeat;
}

.our-team-section .section-bg-image img, .donate-section .section-bg-image img, .mindlab-section .section-bg-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.our-team-section .title-wrapper, .donate-section .title-wrapper, .mindlab-section .title-wrapper {
  grid-area: 1 / 1 / 2 / 2;
  align-items: center;
  font-size: 40px;
  display: flex;
}

.our-team-section .title-wrapper, .donate-section .title-wrapper {
  grid-column: 3 / 4;
}

.our-team-section .description-wrapper, .donate-section .description-wrapper, .mindlab-section .description-wrapper {
  z-index: 2;
  text-align: justify;
  grid-area: 2 / 1 / 4 / 2;
  align-items: center;
  font-size: 18px;
  line-height: 30px;
  display: flex;
}

.our-team-section .description-wrapper, .donate-section .description-wrapper {
  grid-area: 2 / 3 / 4 / 4;
}

.our-team-section .button-wrapper, .donate-section .button-wrapper, .mindlab-section .button-wrapper {
  grid-area: 3 / 1 / 4 / 4;
  justify-content: flex-end;
  margin: 5vh 0;
  display: flex;
}

.our-team-section .button-wrapper, .donate-section .button-wrapper {
  grid-column: 1 / 4;
  justify-content: flex-start;
}

.our-team-section .button-wrapper {
  gap: 20px;
  display: flex;
}

.our-team-section .button-wrapper button, .donate-section .button-wrapper button {
  color: #fff;
  padding: 28px;
  background: linear-gradient(145deg, #375250, #41615f) !important;
}

.our-team-section .button-wrapper div, .donate-section .button-wrapper div, .mindlab-section .button-wrapper div {
  text-align: center;
  cursor: pointer;
  background: #b99095;
  border-radius: 10px;
  width: 200px;
  padding: 16px 10px;
  font-weight: 500;
  box-shadow: 20px 20px 60px #9d7a7f, -20px -20px 60px #d5a6ab;
}

.page-title-section-second, .page-title-section-second .section-bg-image {
  background: none !important;
}

.statistics-section {
  margin: var(--section-margins);
  position: relative;
}

.statistics-section .title-wrapper {
  margin-bottom: 8vh;
  font-size: 40px;
}

.statistics-count-3 {
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  display: grid;
}

.statistics-count-4 {
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  display: grid;
}

.statistic-item {
  border: 1px solid #000;
  border-radius: 10px;
  flex: 1;
  align-items: center;
  padding: 40px;
  display: flex;
  position: relative;
}

.statistic-item .icon-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
}

.statistic-item img {
  width: 48px;
  height: 48px;
}

.statistic-item .stats-description {
  font-size: 18px;
}

.articles-section {
  margin: var(--section-margins);
  position: relative;
}

.mindlab-page .articles-section {
  margin: 0 10vw;
}

.articles-section .title-wrapper {
  font-size: 40px;
}

.desktop-articles-wrapper {
  gap: 0 2vw;
  margin-top: 8vh;
  display: flex;
}

.mindlab-page .desktop-articles-wrapper {
  margin-top: 0;
}

.mobile-articles-wrapper {
  display: none;
}

.see-all-articles {
  cursor: pointer;
  justify-content: flex-end;
  margin-top: 20px;
  font-size: 16px;
  display: flex;
}

.homepage-page .article-wrapper, .mindlab-page .article-wrapper {
  background: #b99095;
  border-radius: 10px;
  flex-direction: column;
  flex: 1;
  display: flex;
  box-shadow: 3px 3px 10px #00000080;
}

.homepage-page .article-wrapper .article-image, .mindlab-page .article-wrapper .article-image {
  cursor: pointer;
  border-radius: 10px 10px 0 0;
  height: 24vh;
  max-height: 24vh;
  overflow: hidden;
}

.homepage-page .article-wrapper .article-image img, .mindlab-page .article-wrapper .article-image img {
  object-fit: cover;
  border-radius: 10px 10px 0 0;
  width: 100%;
  height: 100%;
  transition: all .3s;
}

.homepage-page .article-wrapper .article-image img:hover, .mindlab-page .article-wrapper .article-image img:hover {
  transform: scale(1.2);
}

.homepage-page .article-content, .mindlab-page .article-content {
  color: #000;
  padding: 20px 30px;
}

.homepage-page .article-content .article-title, .mindlab-page .article-content .article-title {
  text-align: center;
  justify-content: center;
  height: 5em;
  max-height: 5em;
  margin-bottom: 14px;
  font-size: 20px;
  display: flex;
  overflow: hidden;
}

.mindlab-page .article-title {
  align-items: center;
}

.ellipsis-title {
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  height: 5em;
  max-height: 5em;
  line-height: 1em;
  display: -webkit-box;
  overflow: hidden;
}

.homepage-page .article-content .article-description, .mindlab-page .article-content .article-description {
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  text-align: left;
  -webkit-box-orient: vertical;
  height: 7.5em;
  max-height: 7.5em;
  margin: 0;
  font-size: 16px;
  line-height: 1.5em;
  display: -webkit-box;
  overflow: hidden;
}

.homepage-page .article-see-more, .mindlab-page .article-see-more {
  cursor: pointer;
  justify-content: flex-end;
  margin-top: 30px;
  font-size: 16px;
  font-style: italic;
  display: flex;
}

.newsletter-section {
  padding: 5vh 15vw;
  position: relative;
}

.newsletter-wrapper {
  border: 2px solid #d6d6d6;
  border-radius: 15px;
  padding: 40px 30px 30px;
}

.newsletter-section .title-wrapper {
  text-align: center;
  margin-bottom: 3vh;
  font-size: 32px;
}

.newsletter-form {
  justify-content: center;
  display: flex;
}

.newsletter-button-wrapper {
  margin-left: 30px;
}

.newsletter-button-wrapper button {
  color: #fff !important;
  background: #3d5b59 !important;
}

.footer-wrapper {
  color: #000;
  background: url("paper2.554fb381.jpg") 0 0 / 100%;
  grid-template-rows: 1fr;
  grid-template-columns: 2fr 1fr 200px;
  gap: 50px;
  padding: 10vh 15vw;
  display: grid;
  position: relative;
  box-shadow: 0 -4px 20px -4px #00000070;
}

.footer-logos-wrapper {
  z-index: 1000;
  grid-area: 1 / 1 / 2 / 4;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.footer-logos-wrapper > div {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.footer-logos-wrapper img {
  object-fit: cover;
  width: 50%;
}

.lab-icon-wrapper {
  flex-direction: column;
  width: 100%;
}

.lab-icon-wrapper > img {
  margin-top: 10px;
}

.footer-wrapper .donate-button-wrapper {
  justify-content: flex-end;
  display: flex;
}

.footer-divider-wrapper {
  clip-path: url("#wave");
  background: url("paper2.554fb381.jpg") 0 0 / 100%;
  height: 100px;
  position: relative;
}

.footer-background-gradient {
  background: #b99095eb;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.footer-contact-bank-details, .footer-contact-wrapper, .footer-social-media-wrapper, .donate-button-wrapper, .social-media-wrapper, .our-team-section, .mindlab-section, .donate-section {
  position: relative;
}

.footer-contact-bank-details {
  grid-area: 2 / 1 / 4 / 2;
}

.footer-contact-wrapper {
  grid-area: 2 / 2 / 4 / 3;
}

.footer-contact-wrapper, .footer-social-media-wrapper {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.footer-social-media-wrapper {
  align-items: flex-end;
}

.social-media-wrapper {
  grid-area: 3 / 3 / 4 / 4;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
}

.donate-button-wrapper {
  grid-area: 2 / 3 / 3 / 4;
}

.social-media-item-wrapper {
  cursor: pointer;
  width: 36px;
  height: 36px;
}

.social-media-item {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.team-members-wrapper {
  min-height: 100vh;
  padding: 0 15vw;
}

.team-member-item {
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  border-radius: 8px;
  grid-template-columns: 1fr 3fr;
  gap: 3vw;
  display: grid;
}

.team-member-item:nth-child(2n) {
  border-left: 2px solid #000;
  border-right: 0;
}

.team-member-item + .team-member-item {
  margin-top: 10vh;
}

.team-member-left-column {
  background: #fff;
  border-radius: 8px;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 2vh;
  display: flex;
}

.image-box-wrapper {
  border-radius: 8px;
  width: 20vw;
  height: 20vw;
  padding: 14px;
}

.team-member-left-column img {
  object-fit: cover;
  border-radius: 8px;
  width: 100%;
  height: 100%;
}

.team-member-left-column .name-and-title {
  text-align: center;
  padding: 0 2vw;
}

.team-member-left-column .linkedin-wrapper, .team-member-left-column .cv-wrapper {
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}

.team-member-description {
  text-align: justify;
  background: #fff;
  border-radius: 8px;
  align-items: center;
  padding: 14px;
  display: flex;
}

.objective-item-wrapper {
  grid-template-rows: 10vh 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 0 20px;
  padding: 0 15vw;
  display: grid;
}

.objective-item-wrapper:nth-child(n+2) {
  margin-top: 10vh;
}

.objective-item-wrapper .title {
  grid-row: 1 / 2;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  display: flex;
}

.objective-item-wrapper .description {
  grid-row: 2 / 3;
}

.objective-item-wrapper .image {
  grid-row: 1 / 3;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  position: relative;
}

.objective-item-wrapper .image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.objective-item-wrapper .paragraph {
  text-align: justify;
  margin-top: 14px;
}

.objective-item-wrapper.image-last .title, .objective-item-wrapper.image-last .description {
  grid-column: 1 / 2;
}

.objective-item-wrapper.image-last .image, .objective-item-wrapper.image-first .title, .objective-item-wrapper.image-first .description {
  grid-column: 2 / 3;
}

.objective-item-wrapper.image-first .image {
  grid-column: 1 / 2;
}

.image-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  mask-image: url("mask1.c03adeba.png");
  mask-position: 0 70%;
  mask-size: 100%;
  mask-repeat: no-repeat;
}

.objective-item-wrapper .bg-image-section.objective-img-1, .objective-item-wrapper .bg-image-section.objective-img-2, .objective-item-wrapper .bg-image-section.objective-img-3, .objective-item-wrapper .bg-image-section.objective-img-4, .objective-item-wrapper .bg-image-section.objective-img-5, .objective-item-wrapper .bg-image-section.objective-img-6 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.form-wrapper {
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  margin-top: 20px;
  display: grid;
}

.form-wrapper .image-wrapper {
  background: url("volunteers_1k.012fb608.webp") center / cover no-repeat;
  grid-area: 1 / 1 / 4 / 2;
}

.form-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.form-input-wrapper .submit-button {
  margin-left: 20px;
}

.form-wrapper .image-wrapper {
  grid-area: 1 / 1 / 4 / 2;
}

.form-wrapper .form-cta-wrapper {
  grid-area: 1 / 2 / 2 / 3;
  align-items: center;
  font-weight: 500;
  display: flex;
}

.form-wrapper .form-input-wrapper {
  grid-area: 2 / 2 / 3 / 3;
  align-items: center;
  display: flex;
}

.form-wrapper .form-submit-button-wrapper {
  grid-area: 3 / 2 / 4 / 3;
}

.form-wrapper .submit-button {
  background: #fff;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #fff;
}

.button {
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 6px;
  height: 48px;
  padding: 12px 30px;
  font-size: 18px;
}

.form-input-wrapper .email-input {
  flex: 1;
  max-width: 500px;
}

.donate-page {
  margin-bottom: 0;
}

.donate-page-content-wrapper {
  padding: 5vh 10vw;
}

.blog-page .articles-wrapper {
  grid-template-columns: repeat(3, 1fr);
  gap: 2vw;
  margin: 10vh 0;
  padding: 0 10vw;
  display: grid;
}

.blog-page .article-wrapper {
  background: #fbfbfb;
  border-radius: 10px;
  overflow: hidden;
}

.blog-page .article-content-wrapper {
  padding: 5%;
}

.blog-page .article-wrapper .article-cover-image {
  cursor: pointer;
  height: 25vh;
  overflow: hidden;
}

.blog-page .article-wrapper .article-cover-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: all .3s;
}

.blog-page .article-wrapper .article-cover-image img:hover {
  transform: scale(1.2);
}

.blog-page .article-wrapper .article-title {
  align-items: center;
  height: 5em;
  margin-bottom: 5%;
  font-size: 18px;
  font-weight: 700;
  line-height: 1em;
  display: flex;
}

.blog-page .article-wrapper .article-highlights {
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  min-width: 0;
  max-width: 100%;
  height: 7.5em;
  font-size: 16px;
  line-height: 1.5em;
  display: -webkit-box;
  overflow: hidden;
}

.blog-page .article-wrapper .read-more-wrapper {
  cursor: pointer;
  margin-top: 2vh;
  font-size: 16px;
  font-style: italic;
}

.team-member-description .read-more-wrapper {
  display: none;
}

.single-article-content-wrapper {
  margin: 5vh 10vw;
}

.single-article-content-wrapper p {
  margin: 2vh 0;
}

.single-article-big-wrapper .message {
  padding: 3vh 10vw;
  font-size: 40px;
}

.single-article-big-wrapper .hero-section {
  height: 55vh;
}

.terms-and-conditions, .confidentiality-policy {
  margin: 110px 10vw 0;
  padding: 5vh 0;
}

.cookies-consent-modal-wrapper {
  z-index: 9999;
  justify-content: flex-end;
  width: 100%;
  padding: 0 2vw;
  display: flex;
  position: fixed;
  bottom: 3vh;
  left: 0;
}

.cookies-consent-modal {
  background: #fff;
  border-radius: 6px;
  gap: 16px;
  width: 100%;
  max-width: 42rem;
  padding: 16px;
  font-size: 14px;
  display: flex;
  box-shadow: 0 0 10px #565656;
}

.cookies-consent-modal .content {
  flex-direction: column;
  flex: 1;
  gap: 10px;
  display: flex;
}

.cookies-consent-modal .content .title {
  font-weight: 700;
}

.buttons-wrapper {
  gap: 16px;
  display: flex;
}

.buttons-wrapper > div {
  cursor: pointer;
}

.cookies-consent-modal .close-button {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.mindlab-page .section-title {
  text-align: center;
  margin-bottom: 18px;
  font-size: 26px;
  font-weight: bold;
}

.mindlab-page .video-container {
  justify-content: center;
  align-items: center;
  height: 50vh;
  margin: 0 10vw 10vh;
  display: flex;
  position: relative;
  overflow: hidden;
}

.mindlab-page .video-container iframe {
  border: 0;
  align-items: center;
  width: 70%;
  height: 100%;
  display: flex;
}

.mindlab-page .research-grid, .mindlab-page .statistics-grid {
  grid-template-columns: repeat(3, 1fr);
  gap: 2vh 2vw;
  margin-bottom: 2vh;
  display: grid;
}

.research-grid {
  margin: 0 10vw;
}

.mindlab-page .research-item {
  color: #000;
  background: #b99095;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  display: flex;
}

.mindlab-page .research-item:nth-child(2n) {
  color: #fff;
  background: #3d5b59;
}

.mindlab-page .research-item .research-item-title {
  text-align: center;
  font-weight: 700;
}

.mindlab-page .research-item .research-item-description {
  text-align: center;
}

.mindlab-page .research-item img {
  object-fit: contain;
  width: 32px;
  height: 32px;
  margin-bottom: 10px;
}

.statistics-grid {
  color: #fff;
  background: #3d5b59;
  margin: 0 10vw;
}

.statistics-grid .statistics-item {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
  display: flex;
}

.statistics-grid .statistics-item .first-line {
  font-size: 40px;
}

.statistics-grid .statistics-item .second-line {
  font-size: 12px;
}

.mobile-articles-list, .mobile-books-list {
  display: none;
}

.articles-list-section, .books-list-section {
  text-align: center;
  color: #fff;
  text-align: justify;
  background: #3d5b59;
  margin: 18px 10vw 0;
  padding: 3vh 0 2vh;
}

.articles-list-section .title, .books-list-section .title {
  text-align: center;
  margin-bottom: 8px;
  font-size: 22px;
}

.articles-list-section .content, .books-list-section .content {
  text-align: justify;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 12vw 3vh;
  display: flex;
}

.articles-list-section .content ul, .books-list-section .content ul {
  list-style-type: disc;
}

.articles-list-section .see-all-button, .books-list-section .see-all-button {
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid #fff;
  margin-top: 20px;
  padding: 12px 20px;
}

.articles-list-section .see-all-button:hover, .books-list-section .see-all-button:hover {
  opacity: .8;
}

.articles-list-section .see-all-button:active, .books-list-section .see-all-button:active {
  opacity: .6;
}

.mindlab-articles-content {
  padding: 5vh 15vw;
}

.ongoing-projects-page {
  margin-bottom: 5vh;
  position: relative;
}

.ongoing-projects-page .project-list {
  gap: 10vh;
  padding: 3vh 15vw;
  display: grid;
}

.ongoing-project-first-line {
  gap: 20px;
  display: flex;
}

.ongoing-projects-page .project-list .ongoing-project {
  gap: 12px;
  display: grid;
}

.ongoing-projects-page .project-list .ongoing-project .ongoing-project-title {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.ongoing-projects-page .project-list .ongoing-project .ongoing-project-image {
  flex: 1;
  max-width: 400px;
}

.ongoing-projects-page .project-list .ongoing-project .ongoing-project-image img {
  object-fit: contain;
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  height: 100%;
}

.ongoing-project-cta {
  color: #fff;
  background: #b99095eb;
  border: 1px solid #959595;
  border-radius: 4px;
  padding: 20px;
}

.our-products-page .project-list {
  gap: 10vh;
  padding: 3vh 15vw;
  display: grid;
}

.our-products-page .project-wrapper {
  grid-template-rows: auto 25vh auto;
  gap: 12px;
  display: grid;
}

.project-wrapper .title {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.project-wrapper .image-wrapper img {
  object-fit: cover;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.testimonials-wrapper {
  grid-template-columns: repeat(3, 1fr);
  gap: 5vh;
  padding: 0 3vw;
  display: grid;
}

.testimonial-wrapper {
  background: linear-gradient(150deg, #f6f6f6 0%, #e3e3e3 100%);
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  gap: 18px;
  padding: 3vh 3vw;
  display: grid;
}

.testimonial-wrapper .image-wrapper {
  justify-content: center;
  display: flex;
}

.testimonial-wrapper .image-wrapper img {
  object-fit: cover;
  border-radius: 50px;
  width: 100px;
  height: 100px;
}

.testimonial-wrapper .description {
  font-style: italic;
}

.testimonial-wrapper .name {
  font-weight: bold;
}

.form-35-title {
  margin: 5vh 0;
}

.form-35-description {
  text-align: center;
  font-weight: bold;
}

.formik-35-form-wrapper {
  flex-direction: column;
  display: flex;
}

.formik-field-error {
  color: #d35151;
}

.formik-line {
  justify-content: space-between;
  gap: 40px;
  margin: 12px 0;
  display: flex;
}

.formik-form-field {
  flex: 1;
}

.formik-form-field label {
  font-size: 12px;
  font-weight: bold;
}

.formik-form-field input {
  border: 1px solid gray;
  border-radius: 4px;
  width: 100%;
  padding: 8px 12px;
}

.formik-form-field input[type="checkbox"] {
  width: 50px;
  min-width: 50px;
  height: 20px;
}

.formik-35-form-wrapper button[type="submit"] {
  cursor: pointer;
  width: 100px;
  margin-top: 20px;
  padding: 10px;
}

.our-mission-content {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.redirect-35-wrapper {
  z-index: 2;
  margin: 5vh 10vw;
  position: relative;
}

@media (width <= 1000px) {
  .desktop-page-menu {
    display: none;
  }

  .mobile-page-menu {
    text-align: right;
    height: 100%;
    display: block;
  }

  .mobile-menu-items-wrapper {
    background: #fff;
    flex-direction: column;
    width: 180px;
    display: flex;
    position: relative;
  }

  .mobile-first-level-wrapper {
    background: #fff;
    width: 100%;
    min-height: 100%;
    transition: all .5s;
    position: absolute;
    top: -10px;
    left: 0;
  }

  .mobile-page-menu .menu-item {
    text-align: left;
    margin: 10px 0;
    padding: 16px 10px 16px 30px;
  }

  .page-top-menu-wrapper {
    padding: 20px 0 20px 20px;
  }

  .who-we-are-page .hero-section, .what-we-do-page .hero-section, .mindlab-page .hero-section, .get-involved-page .hero-section, .donate-page .hero-section, .blog-page .hero-section {
    height: 35vh;
  }

  .statistics-wrapper {
    flex-direction: column;
    gap: 50px;
    display: flex;
  }

  .footer-wrapper {
    grid-template-rows: 1fr 1fr 100px;
    grid-template-columns: 2fr 1fr;
  }

  .footer-logos-wrapper {
    z-index: 1000;
    grid-area: 1 / 1 / 2 / 3;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
  }

  .footer-logos-wrapper > div {
    flex: 1;
  }

  .mindcare-icon-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .lab-icon-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .lab-icon-wrapper > img {
    object-fit: cover;
    width: 50%;
    margin-top: 10px;
  }

  .footer-contact-bank-details {
    grid-area: 2 / 1 / 3 / 2;
  }

  .footer-contact-wrapper {
    grid-area: 2 / 2 / 3 / 3;
  }

  .donate-button-wrapper {
    grid-area: 3 / 2 / 4 / 3;
    align-items: center;
    display: flex;
  }

  .social-media-wrapper {
    grid-area: 3 / 1 / 4 / 2;
  }

  .form-wrapper .form-input-wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .form-input-wrapper .submit-button {
    margin-top: 10px;
    margin-left: 0;
  }

  .form-input-wrapper .email-input {
    width: 100%;
  }

  .values-wrapper {
    grid-template-columns: repeat(6, 1fr);
    justify-content: center;
    align-items: center;
    gap: 24px;
    display: grid;
  }

  .value-item {
    grid-column: span 2;
  }

  .value-item.count-4.index-3 {
    grid-column: 3 / 5;
  }

  .value-item.count-5.index-3 {
    grid-column: 2 / 4;
  }

  .value-item.count-5.index-4 {
    grid-column: 4 / 6;
  }

  .objective-item-wrapper {
    grid-template-rows: 25vh auto auto;
    grid-template-columns: 1fr;
  }

  .objective-item-wrapper.image-last .image, .objective-item-wrapper.image-first .image, .objective-item-wrapper .image {
    grid-area: 1 / 1 / 2 / 2;
  }

  .objective-item-wrapper .image-mask {
    mask-image: none;
  }

  .objective-item-wrapper.image-first .title, .objective-item-wrapper .title {
    grid-area: 2 / 1 / 3 / 2;
    margin: 2vh 0;
  }

  .objective-item-wrapper.image-first .description, .objective-item-wrapper .description {
    grid-area: 3 / 1 / 4 / 2;
  }

  .ongoing-project-first-line {
    flex-direction: column;
    display: flex;
  }

  .ongoing-project-image {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .ongoing-projects-page .project-list .ongoing-project .ongoing-project-image {
    max-width: unset;
  }

  .ongoing-project-image img {
    max-width: 400px;
  }
}

@media (width <= 900px) {
  .blog-page .articles-wrapper {
    grid-template-columns: repeat(2, 1fr);
    margin: 10vh 0;
    padding: 0 10vw;
    display: grid;
  }

  .desktop-articles-wrapper {
    display: none;
  }

  .mobile-articles-wrapper {
    display: block;
  }

  .article-wrapper {
    margin-right: 30px;
  }

  .mindlab-page .research-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width <= 750px) {
  .footer-wrapper {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
  }

  .footer-logos-wrapper {
    z-index: 1000;
    flex-direction: column;
    grid-area: 1 / 1 / 2 / 2;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
  }

  .lab-icon-wrapper {
    margin-top: 20px;
  }

  .footer-contact-bank-details {
    grid-area: 2 / 1 / 3 / 2;
  }

  .footer-contact-wrapper {
    grid-area: 4 / 1 / 5 / 2;
  }

  .social-media-wrapper {
    grid-area: 5 / 1 / 6 / 2;
  }

  .donate-button-wrapper {
    grid-area: 6 / 1 / 7 / 2;
    align-items: center;
    display: flex;
  }

  .newsletter-form {
    flex-direction: column;
  }

  .newsletter-button-wrapper {
    margin: 10px 0 0;
  }

  .values-wrapper {
    grid-template-columns: repeat(6, 1fr);
  }

  .value-item, .value-item.count-4.index-3, .value-item.count-5.index-3 {
    grid-column: span 3;
  }

  .value-item.count-5.index-4 {
    grid-column: 2 / 6;
  }

  .donate-section, .mindlab-section {
    border-radius: 0;
    margin: 5vh 0 0;
  }

  .donate-section .background-gradient, .mindlab-section .background-gradient {
    border-radius: 0;
    grid-template-columns: 1fr;
    padding: 5vh 0 0;
  }

  .donate-section .section-bg-image, .mindlab-section .section-bg-image {
    box-shadow: none;
    background-position: top;
    border-radius: 0;
    grid-area: 4 / 1 / 5 / 2;
    height: 50vh;
  }

  .donate-section .title-wrapper, .mindlab-section .title-wrapper {
    text-align: center;
    grid-area: 1 / 1 / 2 / 2;
    justify-content: center;
    padding: 0 15vw;
  }

  .donate-section .description-wrapper, .donate-section .button-wrapper, .mindlab-section .description-wrapper, .mindlab-section .button-wrapper {
    text-align: center;
    justify-content: center;
    padding: 0 15vw;
  }

  .donate-section .description-wrapper, .mindlab-section .description-wrapper {
    grid-area: 2 / 1 / 3 / 2;
  }

  .donate-section .button-wrapper, .mindlab-section .button-wrapper {
    grid-column: 1 / 2;
  }

  .donate-section .button-wrapper button, .mindlab-section .button-wrapper button {
    padding: 28px;
  }

  .our-team-section {
    border-radius: 0;
    margin: 0;
  }

  .our-team-section .background-gradient {
    border-radius: 0;
    grid-template-columns: 1fr;
    padding: 5vh 0 0;
  }

  .our-team-section .section-bg-image {
    box-shadow: none;
    background-position: top;
    border-radius: 0;
    grid-area: 4 / 1 / 5 / 2;
    height: 50vh;
  }

  .our-team-section .title-wrapper {
    grid-area: 1 / 1 / 2 / 2;
    justify-content: center;
    padding: 0 15vw;
  }

  .our-team-section .description-wrapper, .our-team-section .button-wrapper {
    text-align: center;
    justify-content: center;
    padding: 0 15vw;
  }

  .our-team-section .description-wrapper {
    grid-area: 2 / 1 / 3 / 2;
  }

  .our-team-section .button-wrapper {
    flex-direction: column;
    grid-column: 1 / 2;
    align-items: center;
    display: flex;
  }

  .our-team-section .button-wrapper button {
    padding: 28px;
  }

  .team-member-item {
    grid-template-columns: 1fr;
    gap: 3vw;
    display: grid;
  }

  .team-member-left-column {
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }

  .image-box-wrapper {
    grid-row: 1 / 5;
    width: 30vw;
    height: 30vw;
  }

  .name-and-title {
    grid-row: 1 / 3;
  }

  .team-member-left-column .name-and-title {
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 14px;
    display: flex;
  }

  .image-mask {
    mask-image: none;
  }

  .what-we-do-page .hero-section .message {
    font-size: 24px;
  }

  .testimonials-wrapper {
    grid-template-columns: 1fr;
    padding: 0 20vw;
  }

  .formik-line {
    flex-direction: column;
    gap: 12px;
    margin: 0 0 12px;
    display: flex;
  }
}

@media (width <= 600px) {
  .homepage-page .hero-section .message, .our-mission-content, .statistics-section .title-wrapper, .statistic-item .stats-description {
    text-align: center;
  }

  .hero-section-bg-image {
    background: url("bg1_mobile.4d92ede8.webp") center / cover no-repeat;
  }

  .who-we-are-page .hero-section-bg-image {
    background: url("allteam.70db2c9e.webp") center / cover no-repeat;
  }

  .what-we-do-page .hero-section-bg-image {
    background: url("pawns.a2515dbb.jpg") center / cover no-repeat;
  }

  .get-involved-page .hero-section-bg-image {
    background: url("hands2_mobile.44721c14.webp") center / cover no-repeat;
  }

  .donate-section .section-bg-image, .mindlab-section .section-bg-image {
    background: url("hands_mobile.c5bd0e28.webp") center / cover no-repeat;
  }

  .objective-item-wrapper .bg-image-section.objective-img-1 {
    background: url("o1_alt.eb0f033a.webp") center / cover no-repeat;
    width: 100%;
    height: 100%;
  }

  .objective-item-wrapper .bg-image-section.objective-img-2 {
    background: url("o2_mobile.8f970e66.webp") center / cover no-repeat;
    width: 100%;
    height: 100%;
  }

  .objective-item-wrapper .bg-image-section.objective-img-3 {
    background: url("o3_mobile.9f007f02.webp") center / cover no-repeat;
    width: 100%;
    height: 100%;
  }

  .objective-item-wrapper .bg-image-section.objective-img-4 {
    background: url("o4_mobile.1e481800.webp") center / cover no-repeat;
    width: 100%;
    height: 100%;
  }

  .objective-item-wrapper .bg-image-section.objective-img-5 {
    background: url("o5_mobile.9727468d.webp") center / cover no-repeat;
    width: 100%;
    height: 100%;
  }

  .objective-item-wrapper .bg-image-section.objective-img-6 {
    background: url("o6_mobile.ceda6d99.webp") center / cover no-repeat;
    width: 100%;
    height: 100%;
  }

  .form-wrapper .image-wrapper {
    background: url("volunteers_mobile.689737d4.webp") center / cover no-repeat;
  }

  .transition-wrapper img {
    transform: scale(1.6);
  }

  .homepage-page .page-title-section {
    text-align: center;
    padding: 10vh 5vw;
    font-size: 30px;
    line-height: 30px;
  }

  .title-wrapper {
    font-size: 30px !important;
  }

  .hero-section .message {
    width: 70%;
    font-size: 22px;
  }

  .values-wrapper {
    grid-template-columns: 1fr;
  }

  .value-item {
    flex-direction: row;
    grid-column: span 1;
    display: flex;
  }

  .value-item, .value-item.count-4.index-3, .value-item.count-5.index-3, .value-item.count-5.index-4 {
    grid-column: span 1;
  }

  .value-icon {
    flex: 1;
    min-width: 100px;
    max-width: 100px;
    margin-right: 40px;
  }

  .value-text {
    text-align: left;
    flex: 2;
  }

  .articles-section {
    margin: 10vh 5vw;
  }

  .react-multi-carousel-list {
    margin-left: -10px;
    padding: 10px;
  }

  .articles-section .title-wrapper {
    text-align: center;
    margin: 0 10vw 5vh;
  }

  .team-member-left-column {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .image-box-wrapper {
    width: 50vw;
    height: 50vw;
  }

  .form-wrapper {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: unset;
    gap: 20px;
  }

  .form-wrapper .image-wrapper {
    grid-area: 1 / 1 / 2 / 3;
  }

  .form-wrapper .form-cta-wrapper {
    grid-area: 1 / 3 / 2 / 6;
  }

  .form-wrapper .form-input-wrapper {
    grid-area: 2 / 1 / 3 / 6;
    display: flex;
  }

  .form-wrapper .form-submit-button-wrapper {
    grid-area: 2 / 4 / 3 / 6;
  }

  .form-wrapper input {
    width: unset;
    height: unset;
    min-width: unset;
    max-width: unset;
  }

  .button {
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    min-width: 130px;
    height: 40px;
    padding: 8px 18px;
    font-size: 14px;
    display: flex;
  }

  .blog-page .articles-wrapper {
    grid-template-columns: 1fr;
    gap: 5vh 0;
    margin: 3vh 0;
  }

  .blog-page .articles-wrapper .article-title {
    height: unset;
  }

  .blog-page .articles-wrapper .article-wrapper {
    margin: 0;
  }

  .mindlab-page .statistics-grid {
    grid-template-columns: 1fr;
  }

  .statistics-grid .statistics-item {
    grid-column: 1 / 2;
  }

  .statistics-grid .statistics-item .first-line {
    font-size: 32px;
  }

  .team-member-description {
    flex-direction: column;
    display: flex;
    position: relative;
    overflow: hidden;
  }

  .team-member-description .read-more-wrapper {
    border: 1px solid #000;
    border-radius: 10px;
    margin: 12px 20px;
    padding: 12px 20px;
    display: block;
  }

  .team-member-description.short-version .content {
    text-overflow: ellipsis;
    -webkit-line-clamp: 10;
    line-clamp: 10;
    text-align: center;
    -webkit-box-orient: vertical;
    height: 10em;
    max-height: 10em;
    line-height: 1em;
    display: -webkit-box;
    overflow: hidden;
  }

  .team-member-description.long-version .content {
    text-overflow: ellipsis;
    text-align: center;
    line-height: 1em;
    display: block;
    overflow: visible;
  }

  .team-member-description.long-version .read-more-wrapper {
    display: none;
  }

  .who-we-are-page .page-title-section, .what-we-do-page .page-title-section, .mindlab-page .page-title-section, .get-involved-page .page-title-section {
    text-align: center;
  }

  .objective-item-wrapper .paragraph {
    text-align: left;
  }

  .get-involved-content-wrapper {
    grid-template-columns: 1fr;
    gap: 5vh;
  }

  .contact-us-wrapper, .volunteer-wrapper {
    padding: 10vw;
  }

  .desktop-articles-list, .desktop-books-list {
    display: none;
  }

  .mobile-articles-list, .mobile-books-list {
    display: block;
  }

  .ongoing-project-first-line {
    flex-direction: column;
    display: flex;
  }
}

@media (width <= 450px) {
  .form-wrapper {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: unset;
    gap: 20px;
  }

  .form-wrapper .form-input-wrapper {
    grid-column: 1 / 6;
  }

  .form-wrapper .form-submit-button-wrapper {
    grid-area: 3 / 1 / 4 / 6;
  }

  .mindlab-page .research-grid {
    grid-template-columns: 1fr;
  }

  .statistics-grid .statistics-item {
    height: 80px;
  }

  .testimonials-wrapper {
    grid-template-columns: 1fr;
    padding: 0 10vw;
  }
}

@media (width >= 1800px) {
  .inner-top-menu-wrapper {
    width: 1400px;
  }

  .objective-item-wrapper .bg-image-section.objective-img-1 {
    background: url("o1_alt.eb0f033a.webp") center / cover no-repeat;
    width: 100%;
    height: 100%;
  }

  .objective-item-wrapper .bg-image-section.objective-img-2 {
    background: url("o2_2k.32396089.webp") center / cover no-repeat;
    width: 100%;
    height: 100%;
  }

  .objective-item-wrapper .bg-image-section.objective-img-3 {
    background: url("o3_2k.c79828b4.webp") center / cover no-repeat;
    width: 100%;
    height: 100%;
  }

  .objective-item-wrapper .bg-image-section.objective-img-4 {
    background: url("o4_2k.2f973a4e.webp") center / cover no-repeat;
    width: 100%;
    height: 100%;
  }

  .objective-item-wrapper .bg-image-section.objective-img-5 {
    background: url("o5_2k.0f68e82c.webp") center / cover no-repeat;
    width: 100%;
    height: 100%;
  }

  .objective-item-wrapper .bg-image-section.objective-img-6 {
    background: url("o6_2k.827a15fa.webp") center / cover no-repeat;
    width: 100%;
    height: 100%;
  }

  .form-wrapper .image-wrapper {
    background: url("volunteers_2k.a5058632.webp") center / cover no-repeat;
  }

  .hero-section .message, .who-we-are-page .hero-section .message, .what-we-do-page .hero-section .message, .mindlab-page .hero-section .message, .blog-page .hero-section .message, .get-involved-page .hero-section .message, .donate-page .hero-section .message {
    background: none;
    justify-content: flex-end;
    align-items: center;
    width: 1400px;
    height: 100%;
    padding: 0;
    display: flex;
    position: relative;
    top: 0;
    left: 0;
  }

  .homepage-page .hero-section .message .inner-wrapper {
    background: linear-gradient(146deg, #d2ddc0b3 0%, #d2ddc0 80%);
    width: 1000px;
  }

  .hero-section .message .inner-wrapper {
    background: linear-gradient(146deg, #a6a9a2ad 0%, #4e4b46 85%);
    border-radius: 8px;
    padding: 5vh 5vw;
  }

  .menu-item {
    margin: 0 24px;
    font-size: 16px;
  }

  .hero-section {
    justify-content: center;
    height: 50vh;
    display: flex;
  }

  .homepage-page .hero-section .message {
    width: 1400px;
  }

  .page-title-section {
    justify-content: center;
    min-height: 20vh;
    margin: 10vh 20vw;
    font-size: 38px;
    line-height: 38px;
    display: flex;
  }

  .page-title-section .inner-wrapper {
    width: 1400px;
  }

  .title-wrapper {
    font-size: 40px;
  }

  .values-section {
    width: 1400px;
  }

  .values-wrapper .value-item .value-icon {
    border-radius: 80px;
    width: 160px;
    height: 160px;
  }

  .values-wrapper .value-item .value-title {
    margin: 20px 0;
    font-size: 22px;
    font-weight: 500;
  }

  .values-wrapper .value-item .value-description {
    font-size: 20px;
  }

  .our-team-section .title-wrapper, .donate-section .title-wrapper, .mindlab-section .title-wrapper {
    font-size: 40px;
  }

  .our-team-section .description-wrapper, .donate-section .description-wrapper, .mindlab-section .description-wrapper {
    font-size: 22px;
    line-height: 32px;
  }

  .our-team-section .button-wrapper div, .donate-section .button-wrapper div, .mindlab-section .button-wrapper div {
    width: unset;
    padding: 26px 50px;
  }

  .donate-section .section-bg-image, .mindlab-section .section-bg-image {
    background: url("hands_2k.a9548587.webp") center / cover no-repeat;
    height: 40vh;
  }

  .statistics-section .title-wrapper {
    font-size: 48px;
  }

  .statistics-section .statistic-item {
    font-size: 20px;
  }

  .articles-section .title-wrapper {
    font-size: 48px;
  }

  .article-content .article-title {
    font-size: 28px;
  }

  .article-content .article-description {
    max-height: 10vh;
    font-size: 18px;
    line-height: 26px;
  }

  .article-content .article-see-more {
    margin-top: 32px;
    font-size: 20px;
  }

  .newsletter-section .title-wrapper {
    margin-bottom: 3vh;
    font-size: 48px;
  }

  .footer-wrapper {
    padding: 10vh 10vw;
    font-size: 20px;
    line-height: 32px;
  }

  .team-members-wrapper .team-member-item {
    font-size: 24px;
    line-height: 38px;
  }

  .what-we-do-page .objective-item-wrapper .title {
    font-size: 30px;
  }

  .what-we-do-page .objective-item-wrapper .paragraph {
    font-size: 22px;
  }

  .get-involved-page .description {
    font-size: 20px;
  }

  .get-involved-page .form-wrapper {
    height: 20vh;
  }

  .get-involved-page .form-cta-wrapper {
    font-size: 28px;
  }

  .our-mission-content {
    width: 1400px;
  }

  .who-we-are-page .hero-section {
    display: flex;
  }
}

.f230ro-lansare {
  width: 150px;
  margin-top: 20px;
  padding: 10px;
}
/*# sourceMappingURL=index.4f478ede.css.map */
