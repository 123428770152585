:root {
    --main-bg-color: #ABC7A1;
    --main-text-color: #544251;

    --secondary-bg-color: #C7A1C2;
    --secondary-text-color: #1A3310;

    --ternary-bg-color: #55724B;
    --ternary-text-color: #E4FEDB;

    --article-bg-color: #f4f7f2;

    --section-margins: 5vh 15vw;

    --dark-color: #3d5b59;
}

html, body, * {
    margin: 0;
    padding: 0;  
    box-sizing: border-box;

    font-family: Roboto;
}

@font-face {
    font-family: Roboto;
    src: url(fonts/Roboto-Regular.ttf);
}

@font-face {    
    font-family: Rock3D;
    src: url(fonts/Rock3D-Regular.ttf);
}

@font-face {
    font-family: Frederika;
    src: url(fonts/Fredericka_the_Great/FrederickatheGreat-Regular.ttf)
}
  
@font-face {
    font-family: Caveat;
    src: url(fonts/Caveat/Caveat-VariableFont_wght.ttf)
}
  
  
@font-face {
    font-family: Montserrat;
    src: url(fonts/Montserrat/Montserrat-VariableFont_wght.ttf)
}

@font-face {
    font-family: Poppins;
    src: url(fonts/Poppins-Light.ttf)
}

@font-face {
    font-family: SpaceGrotesk;
    src: url(fonts/Space_Grotesk/SpaceGrotesk-VariableFont_wght.ttf);
}
  
  
#app {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* font-family: Montserrat; */

    position: relative;
    color: var(--dark-color);
}


#app::before {
    content: "";
    background: url(./img/paper1.jpg) repeat;
    background-size: 100%;
    /* background: green; */
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.20;
}



.page-top-menu-wrapper {   
    display: flex;
    padding: 20px 50px;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    right: 0;
    z-index: 9999;
    background-color: white;
    height: 110px;
    align-items: center;
    justify-content: center;
}

.inner-top-menu-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;    
}

.logo-wrapper {
    position: relative;
}

.page-logo {
    position: absolute;
    height: 76px;
    cursor: pointer;
}

.desktop-page-menu {
    display: flex;
    align-items: center;
    height: 100%;   
}

.desktop-page-menu .menu-item {
    transition: 0.2s all;
}

.desktop-page-menu .menu-item:hover {
    /* transform: translateY(-2px); */
    opacity: 0.5;
    /* color: black; */
    /* text-decoration: underline; */
}

.mobile-page-menu {
    display: none;
}

.menu-item {
    font-weight: normal;
    display: flex;
    align-items: center;
    margin: 0 12px;
    font-size: 15px;

    cursor: pointer;
    user-select: none;
    font-family: SpaceGrotesk;
}

.menu-item-active {
    font-weight: bold;
}

.menu-item-redirect35 {
    color: #FCB5AC;

    border: 1px solid #FCB5AC;
    padding: 10px 16px;
    height: 40px;
    border-radius: 8px;

    /* color: #3d5b59; */
}

.menu-item-donate {
    padding: 10px 16px;
    border-radius: 8px;
    height: 40px;
    background: #FCB5AC;
    /* color: #FCB5AC; */

    color: #FFFFFF;
    /* font-weight: 700 !important; */
}

.language-picker-wrapper {
    display: flex;
    align-items: flex-end;
    align-items: center;
    margin-left: 20px;
}

.mobile-language-picker-wrapper {
    display: flex;
    align-items: center;
    height: 50px;
    margin: 0 24px 10px;
    text-align: center;
}

.mobile-language-picker-wrapper > .language-item {
    flex: 1;
}

.language-picker-flag-item {
    height: 12px;
    width: 20px;
    margin: 0 6px;
}


.language-item {
    cursor: pointer;
}

.language-item.inactive {
    opacity: 0.5;
}

.hero-section {
    width: 100%;
    height: 55vh;
    position: relative;
    margin-top: 110px;
}

.hero-section-bg-image {
    position: absolute;
    top: 0; 
    left: 0;
    right: 0;
    bottom: 0;
    background: url(./img/bg1_1k.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.our-mission-title {
    font-size: 40px;
    margin: 5vh 0;
    color: #3d5b59 ;
    text-align: center;
    width: 100%;
}

.our-mission-content {
    text-align: justify;
    font-size:  18px;
    line-height:  22px;
    color: #3d5b59 ;
   
}

.who-we-are-page .hero-section,
.what-we-do-page .hero-section,
.mindlab-page .hero-section,
.get-involved-page .hero-section,
.donate-page .hero-section,
.blog-page .hero-section {  
    height: 65vh;
}


.who-we-are-page .hero-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
}


.whote-we-are-page .hero-section-bg-image {
    position: relative;
}

.who-we-are-page .hero-section-bg-image {
    /* background: url(./img/test1.webp); */
    background: url(./img/allteam.webp);
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position: center; */
    background-position: 0 28%;
}

.what-we-do-page .hero-section-bg-image {
    /* background: url(./img/team3.webp); */
    background: url(./img/pawns.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 30%;
}

.mindlab-page .hero-section-bg-image {
    /* background: url(./img/test1.webp); */
    background: url("https://images.unsplash.com/photo-1498243691581-b145c3f54a5a?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position: center; */
    background-position: 0 28%;
}

.get-involved-page .hero-section-bg-image {
    background: url(./img/hands2_1k.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.blog-page .hero-section-bg-image {
    background: url(./img/blog.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.donate-page .hero-section-bg-image {
    /* background: url(./img/test1.webp); */
    background: url(./img/donate-bg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position: center; */
    background-position: center;
}

.hero-section img {
    width: 100%;
    height: 100%;
    object-fit: cover;   
}

.title-wrapper {
    font-size: 40px;
}

.hero-section .message {
    background: rgb(210,221,192);
    background: linear-gradient(146deg, rgba(210,221,192,0.7) 0%, rgba(210,221,192,1) 80%);
    position: absolute;
    bottom: 25%;
    right: 5%;
    display: flex;
  
   
   
    width: 40%;
    color: white;
    font-size: 24px;
    border-radius: 8px;

    text-align: right;  
    font-style: italic;
   
    min-height: 15vh;
    padding: 5vh 5vw;
    align-items: center;
    justify-content: flex-end;
}

/* .homepage-page .hero-section .message {
    padding: 5vh 5vw;
    width: 60%;
    min-height: 20vh;   
} */

.homepage-page,
.who-we-are-page,
.what-we-do-page,
.mindlab-page,
.blog-page,
.terms-and-conditions,
.confidentiality-policy,
.donate-page,
.get-involved-page,
.our-products-page {   
    position: relative;
    margin-bottom: 5vh;
}

.contact-us-wrapper,
.volunteer-wrapper {
    padding: 3vh 5vw;
    background: #b99095eb;
    border-radius: 20px;
    color: black;
    display: flex;
    flex-direction: column;
}

.contact-us-wrapper .raw-content-wrapper {
    flex: 1;
}

.contact-us-wrapper .social-media-wrapper {
    max-width: 160px;
}

.volunteer-wrapper {
    background: #3d5b59b6;
    color: white;
}

.objectives-wrapper {
    min-height: 20vw;
}

.who-we-are-page .hero-section .message,
.mindlab-page .hero-section .message {
    /* width: 60%; */
    background: #F2EEEB;
    background: linear-gradient(146deg, #A6A9A2ad 0%, #4E4B46 85%);
    /* background: linear-gradient(146deg, #F2EEEBab 0%, #F2EEEB 80%);    */
}


.what-we-do-page .hero-section .message {
    /* width: 60%; */
    background: #F2EEEB;
    background: linear-gradient(146deg, #A6A9A2ad 0%, #4E4B46 85%);
     
}

.blog-page .hero-section .message {
    background: #F2EEEB;
    background: linear-gradient(146deg, #A6A9A2ad 0%, #4E4B46 85%);
}

.donate-page .hero-section .message {
    background: #F2EEEB;
    background: linear-gradient(146deg, #A6A9A2ad 0%, #4E4B46 85%);
}

.get-involved-page .hero-section .message {
    /* width: 60%; */
    background: #F2EEEB;
    /* background: linear-gradient(146deg, #8a463cc7  0%, #8a463cf0  85%); */
    background: linear-gradient(146deg, #A6A9A2ad 0%, #4E4B46 85%);
}


.get-involved-content-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2vw;

    padding: 0 10vw;
}


.transition-wrapper {
    overflow: hidden;
    position: relative;
    margin-top: -8vh;
    top: 1px;
}

.transition-wrapper img {
    width: 100%;
    transform: scale(1.4);
}

.transition-wrapper-bottom {
    top: -1px;
    overflow: hidden;
    position: relative;
}


.transition-wrapper-bottom img {
    transform: scale(1.2) rotate(180deg);
    width: 100%;
}



.page-title-section {
    font-size: 32px;
    /* margin: var(--section-margins); */
    margin: 0 10vw;
    min-height: 30vh;
    padding: 3vh 0;
    text-align: justify; 
    line-height: 32px;
    font-family: Poppins;
    position: relative;
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    /* margin-bottom: 10vh; */
    /* font-style: italic; */


    
}

.homepage-page .page-title-section {
    margin: 0;
    padding: 3vh 10vw;
    background: #B99095;
    color: white;
}

.page-title-section span {
    font-weight: bold;
}

.who-we-are-page .page-title-section,
.what-we-do-page .page-title-section,
.mindlab-page .page-title-section,
.get-involved-page .page-title-section,
.our-products-page .page-title-section,
.ongoing-projects-page .page-title-section
 {
    font-size: 20px;
    line-height: 24px;
    text-align: justify;
    padding: 10vh 0;  
}

.values-section {
    margin: var(--section-margins);
    position: relative;
}

.values-section .title-wrapper {
    margin-bottom: 30px;
    text-align: center;
}


/* .values-wrapper {
    display: flex;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 50px;
    margin-top: 8vh;
} */


.values-wrapper {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    /* grid-template-rows: 1fr 1fr; */
    align-items: center;
    justify-content: center;
    gap: 50px;
}


.value-item {
    display: flex;
    flex-direction: column;
    align-items: center;   

    grid-column: span 2;
}

.value-item.count-5.index-3 {
    grid-column: 2 / 4;
}



.value-icon {
    background: rgb(255,255,255);
    background: linear-gradient(146deg, rgba(255,255,255,1) 0%, rgba(252,181,172,0.3) 80%);

    width: 100px;
    height: 100px;
    border-radius: 50px;

    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 5px 5px 10px #0000001A;
}

.value-icon img {
    width: 60%;
    height: 60%;
    object-fit: contain;
}

.value-text {
    text-align: center;
}

.value-title {
    font-weight: bold;
    font-size: 20px;
    margin: 10px 0;
}

.value-description {
    font-size: 18px;
}





/* .donate-section, */
.mindlab-section {
    background: url(img/paper1.jpg);
    margin: 10vh 0 10vh 7.5vw;
    background-size: 100%;
    color: white;

    border-radius: 30px 0 0 0;


    border-radius: 30px 0 0 0;
    margin: 10vh 0 10vh 7.5vw;
}



.our-team-section,
.donate-section {
    background: url(img/paper1.jpg);
    margin: 10vh 7.5vw 10vh 0;
    background-size: 100%;
    color: white;

    border-radius: 0 30px 0 0;
}

.our-team-section-outer-wrapper {
    position: relative;
    background: red;
    /* width: 1400px; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.our-team-section-content-wrapper {
    width: 1400px;
    background: pink;

    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
}

.our-team-section .background-gradient,
.donate-section .background-gradient,
.mindlab-section .background-gradient {

    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-rows: repeat(3, 1fr); */

    /* margin: 10vh 0 10vh 7.5vw; */
    padding: 5vh 7.5vw;
    /* grid-column: 1/4;
    grid-row: 1/4;  */
    background: rgb(185,144,149);
    background: linear-gradient(329deg, rgba(185,144,149,1) 30%, rgba(185,144,149,0.633578431372549) 100%);

    border-radius: 30px 0 0 0;

    box-shadow: 2px 2px 5px #00000080;
}

.our-team-section .background-gradient,
.donate-section .background-gradient {
    border-radius: 0 30px 0 0;
}

.our-team-section .background-gradient,
.donate-section .background-gradient  {
    background: linear-gradient(329deg, rgba(61,91,89,1) 30%, rgba(61,91,89,0.7148109243697479) 100%);
 }



.our-team-section .section-bg-image, 
.donate-section .section-bg-image,
.mindlab-section .section-bg-image {
    grid-column: 2/4;
    grid-row: 1/3;

    height: 50vh;

    background: url(./img/ourTeam.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;

    border-radius: 0 20px 0 20px;
    box-shadow: 3px 3px 10px #00000080;
}

/* .mindlab-section .section-bg-image-new {
    background: url(./img/mindlab-cover.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
} */

.our-team-section .section-bg-image,
.donate-section .section-bg-image {
    grid-column: 1/3;    
}


.donate-section .section-bg-image {
    background: url(./img/hands_1k.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.mindlab-section .section-bg-image  {
    background: url(./img/mindlab-cover.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.our-team-section .section-bg-image img ,
.donate-section .section-bg-image img,
.mindlab-section .section-bg-image img  {
    width: 100%;
    height: 100%;
    object-fit: cover;

    
}

.our-team-section .title-wrapper ,
.donate-section .title-wrapper,
.mindlab-section .title-wrapper{
    grid-column: 1/2;
    grid-row: 1/2;

    font-size: 40px;
    display: flex;
    align-items: center;
}

.our-team-section .title-wrapper,
.donate-section .title-wrapper  {
    grid-column: 3/4;
}


.our-team-section .description-wrapper,
.donate-section .description-wrapper,
.mindlab-section  .description-wrapper{
    grid-column: 1/2;
    grid-row: 2/4;

    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 30px;
    z-index: 2;

    text-align: justify;
}  


.our-team-section .description-wrapper,
.donate-section .description-wrapper {
    grid-column: 3/4;
    grid-row: 2/4;
}


.our-team-section .button-wrapper ,
.donate-section .button-wrapper,
.mindlab-section .button-wrapper{
    grid-column: 1/4;
    grid-row: 3/4;

    display: flex;
    justify-content: flex-end;

    margin: 5vh 0;
}

.our-team-section .button-wrapper,
.donate-section .button-wrapper {
    grid-column: 1/4;
    justify-content: flex-start;
}

.our-team-section .button-wrapper {
    display: flex;
    gap: 20px;
}

.our-team-section .button-wrapper button,
.donate-section .button-wrapper button {
    background: linear-gradient(145deg, #375250, #41615f) !important;
    color: white;
    padding: 28px;
}



.our-team-section .button-wrapper div ,
.donate-section .button-wrapper div,
.mindlab-section .button-wrapper div {
    width: 200px;
    text-align: center;

    padding: 16px 10px;

    border-radius: 10px;
    background: #B99095;
    box-shadow:  20px 20px 60px #9d7a7f,
                -20px -20px 60px #d5a6ab;

    cursor: pointer;
    font-weight: 500;
}



.page-title-section-second {
    background: none !important;
    /* background: url("./img/paper1.jpg") 0 0 / 100% !important; */
}

.page-title-section-second .section-bg-image {
    background: none !important;
    /* background: linear-gradient(329deg, #b99095 30%, #b99095a2 100%); */
}


.statistics-section  {
    margin: var(--section-margins);
    position: relative;
}

.statistics-section .title-wrapper {
    font-size: 40px;
    margin-bottom: 8vh;
}


.statistics-count-3 {
    display: grid;   
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
}
.statistics-count-4 {
    display: grid;   
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
}


.statistic-item {
    position: relative;
    border: 1px solid black;
    border-radius: 10px;
    padding: 40px;

    flex: 1;

    display: flex;
    align-items: center;
}

.statistic-item .icon-wrapper {
   

    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
    position: absolute;
    top: -30px;

    
}

.statistic-item img {
    width: 48px;
    height: 48px;

  
   
}

.statistic-item .stats-description {
    font-size: 18px;
}

.articles-section {
    margin: var(--section-margins);
    position: relative;
}

.mindlab-page .articles-section {
    margin: 0 10vw;
}

.articles-section .title-wrapper {
    font-size: 40px;
}

.desktop-articles-wrapper {
    display: flex;
    /* justify-content: center; */
    gap: 0 2vw;

    margin-top: 8vh;
}

.mindlab-page .desktop-articles-wrapper {
    margin-top: 0px;
}

.mobile-articles-wrapper {
    display: none;
}

.see-all-articles {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    font-size: 16px;
    cursor: pointer;
}

.homepage-page .article-wrapper,
.mindlab-page .article-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    /* max-width: 250px; */

    background: #B99095;

    border-radius: 10px;
    box-shadow: 3px 3px 10px #00000080;

    /* margin: 0 30px; */
}

.homepage-page .article-wrapper .article-image,
.mindlab-page .article-wrapper .article-image {  
    height: 24vh;     
    max-height: 24vh;
    overflow: hidden;
    cursor: pointer;
    border-radius: 10px 10px 0 0;
}

.homepage-page  .article-wrapper .article-image img,
.mindlab-page  .article-wrapper .article-image img  {
    width: 100%;
    height: 100%;
    object-fit: cover;

    border-radius: 10px 10px 0 0;
    transition: 0.3s all;
}

.homepage-page  .article-wrapper .article-image img:hover,
.mindlab-page  .article-wrapper .article-image img:hover {
    transform: scale(1.2);
}

.homepage-page .article-content,
.mindlab-page .article-content {
    color: black;
    padding: 20px 30px;
}

.homepage-page .article-content .article-title,
.mindlab-page .article-content .article-title {
    font-size: 20px;
    margin-bottom: 14px;
    height: 5em;
    max-height: 5em;
    
    /* align-items: center; */
    justify-content: center;
    text-align: center;
    display: flex;
    overflow: hidden;  
}

.mindlab-page .article-title {
    align-items: center;
}

.ellipsis-title {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1em;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
  
    height: 5em;
    max-height: 5em;
}

.homepage-page .article-content .article-description,
.mindlab-page .article-content .article-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5; /* Limits to 5 lines */
    line-height: 1.5em; /* Adjust based on your desired line height */
    height: 7.5em;
    max-height: 7.5em; /* Calculate max-height based on line-height */
    margin: 0;

    font-size: 16px;

    text-align: left;
}

.homepage-page .article-see-more,
.mindlab-page .article-see-more  {
    margin-top: 30px;
    font-size: 16px;
    display: flex;
    justify-content: flex-end;

    cursor: pointer;
    font-style: italic;
}










.newsletter-section {
    /* margin: var(--section-margins); */
    /* margin: 5vh 0 0; */
    padding: 5vh 15vw;
    /* margin-bottom: 5vh; */
    position: relative;
    /* background: #ffffffb8; */
}

/* .who-we-are-page .newsletter-section {
    margin: 10vh 15vw;
} */

.newsletter-wrapper {
    /* background: #a3c2c0; */
    border: 2px solid #d6d6d6;
    padding: 40px 30px 30px;
    border-radius: 15px;
    /* margin: 60px auto 25px; */
}


.newsletter-section .title-wrapper {
    font-size: 32px;
    margin-bottom: 3vh;
    text-align: center;
    /* color: #FFFFFF; */
}

.newsletter-form {
    display: flex;
    justify-content: center;
}
/* 
.newsletter-form * {
    color: #FFFFFF !important;
} */


.newsletter-button-wrapper {
    margin-left: 30px;
  
}


.newsletter-button-wrapper button {
    background: #3d5b59 !important;
    color: white !important;
}






.footer-wrapper {
    background: url(img/paper2.jpg);


    /* clip-path: url(#wave); */

    background-size: 100%;
    position: relative;

    padding: 10vh 15vw;
    /* padding: var(--section-margins) 10vh; */

    display: grid;
    gap: 50px;
    grid-template-columns: 2fr 1fr 200px;
    grid-template-rows: 1fr;

    box-shadow: 0px -4px 20px -4px #00000070;

    color: black;
}

.footer-logos-wrapper {
    display: flex;  
    position: relative;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    grid-column: 1/4;
    grid-row: 1/2;
}



.footer-logos-wrapper > div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-logos-wrapper img {
    object-fit: cover;
    width: 50%;
}

.lab-icon-wrapper {
    width: 100%;
    flex-direction: column;  
}

.lab-icon-wrapper > img {
    margin-top: 10;   
}

.footer-wrapper .donate-button-wrapper {
    display: flex;
    justify-content: flex-end;
}

.footer-divider-wrapper {
    background: url(img/paper2.jpg);
    background-size: 100%;
  
    height: 100px;
    position: relative;
    

    clip-path: url(#wave);
}


.footer-background-gradient {
   
    background: #B99095eb;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
}

.footer-contact-bank-details,
.footer-contact-wrapper,
.footer-social-media-wrapper,
.donate-button-wrapper,
.social-media-wrapper,
.our-team-section,
.mindlab-section,
.donate-section {
    position: relative;
}

.footer-contact-bank-details {
    grid-column: 1/2;
    grid-row: 2/4;
}

.footer-contact-wrapper {
    grid-column: 2/3;
    grid-row: 2/4;
}

.footer-contact-wrapper,
.footer-social-media-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer-social-media-wrapper {
    align-items: flex-end;
}


.social-media-wrapper {

    grid-column: 3/4;
    grid-row: 3/4;

    width: 100%;
    display: flex;
    gap: 10px;

   
    align-items: center;
    justify-content: space-between;
}


.donate-button-wrapper {
    grid-column: 3/4;
    grid-row: 2/3;
}


/* .donate-button {
    color: white;

    border-radius: 10px;
    background: #B99095;
    box-shadow:  20px 20px 60px #9d7a7f,
                -20px -20px 60px #d5a6ab;

    text-align: center;
    padding: 16px 10px;
    width: 200px;

    cursor: pointer;
} */

.social-media-item-wrapper {
    width: 36px;
    height: 36px;
    cursor: pointer;
}

.social-media-item {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.team-members-wrapper {
    padding: 0 15vw;
    min-height: 100vh;   
}


.team-member-item {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 3vw;
}

.team-member-item {
    border-top: 2px solid black;
    border-right: 2px solid black;
    border-radius: 8px;
}

.team-member-item:nth-child(2n) {
    border-right: 0;
    border-left: 2px solid black;
}

.team-member-item + .team-member-item {
    margin-top: 10vh;
}

.team-member-left-column {
    display: flex;
    flex-direction: column;
    gap: 10px;

    /* margin-top: 14px; */
    background: white;
    border-radius: 8px;

    padding-bottom: 2vh;
}

.image-box-wrapper {
    width: 20vw;
    height: 20vw;

    border-radius: 8px;

    padding: 14px;
    

    /* background: gray; */
}

.team-member-left-column  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    border-radius: 8px;
}

.team-member-left-column .name-and-title {
    text-align: center;  
    /* flex: 1; */
    padding: 0 2vw;
}

.team-member-left-column .linkedin-wrapper,
.team-member-left-column .cv-wrapper {
    text-align: center;    
    font-size: 14px;
    cursor: pointer;
}


.team-member-description {
   display: flex; 
   align-items: center;
   padding: 14px;
   background: white;

   border-radius: 8px;
   text-align: justify;
}



.objective-item-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 10vh 1fr;
    gap: 0 20px;
    padding: 0 15vw;
    /* margin-top: 10vh; */
}

.objective-item-wrapper:nth-child(n+2) {
    margin-top: 10vh;
}


.objective-item-wrapper .title {
    grid-row: 1/2;
    font-size: 20px;
    display: flex;
    align-items: center;
    font-weight: 500;
}

.objective-item-wrapper .description { 
    grid-row: 2/3;
}


.objective-item-wrapper .image { 
    grid-row: 1/3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.objective-item-wrapper .image {    
    height: 100%;
    position: relative;  
}


.objective-item-wrapper .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;   
}


.objective-item-wrapper .paragraph {
    margin-top: 14px;
    text-align: justify;
    /* font-size: 14px; */
}

.objective-item-wrapper.image-last .title,
.objective-item-wrapper.image-last .description {
    grid-column: 1/2;  
}

.objective-item-wrapper.image-last .image { 
    grid-column: 2/3;
   
}



.objective-item-wrapper.image-first .title,
.objective-item-wrapper.image-first .description {
    grid-column: 2/3;   
}

.objective-item-wrapper.image-first .image { 
    grid-column: 1/2;
   
}

.image-mask {
    position: absolute;

    top: 0;
    bottom: 0;
    left:0;
    right: 0;

    width: 100%;
    height: 100%;

  
    mask-image: url(./img/mask1.png);
    -webkit-mask-image:url(./img/mask1.png);
    mask-repeat: no-repeat;   
    mask-size: 100%;
    mask-position: 0% 70%;
}

.objective-item-wrapper .bg-image-section.objective-img-1 {
    /* background: url(./img/o1_alt.webp); */
    width: 100%;
    height: 100%;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.objective-item-wrapper .bg-image-section.objective-img-2 {
    /* background: url(./img/o2_1k.webp); */
    width: 100%;
    height: 100%;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.objective-item-wrapper .bg-image-section.objective-img-3 {
    /* background: url(./img/o3_1k.webp); */
    width: 100%;
    height: 100%;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}



.objective-item-wrapper .bg-image-section.objective-img-4 {
    /* background: url(./img/o4_1k.webp); */
    width: 100%;
    height: 100%;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.objective-item-wrapper .bg-image-section.objective-img-5 {
    /* background: url(./img/o5_1k.webp); */
    width: 100%;
    height: 100%;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.objective-item-wrapper .bg-image-section.objective-img-6 {
    /* background: url(./img/o6_1k.webp); */
    width: 100%;
    height: 100%;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}








.form-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 40px;
    margin-top: 20px;
}

.form-wrapper .image-wrapper {
    grid-column: 1/2;
    grid-row: 1/4;

    background: url(./img/volunteers_1k.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.form-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.form-input-wrapper .submit-button {
    margin-left: 20px;
}

/* .form-wrapper .other-wrapper {
    grid-column: 2/3;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
} */

.form-wrapper .image-wrapper {
    grid-column: 1/2;
    grid-row: 1/4;
}

.form-wrapper .form-cta-wrapper {
    grid-column: 2/3;
    grid-row: 1/2;

    display: flex;
    align-items: center;

    font-weight: 500;
}

.form-wrapper .form-input-wrapper {
    grid-column: 2/3;
    grid-row: 2/3;

    display: flex;
    align-items: center;

    /* background: red; */
}

.form-wrapper .form-submit-button-wrapper {
    grid-column: 2/3;
    grid-row: 3/4;

}



.form-wrapper .submit-button {
    /* cursor: pointer; */
    background: #fff;
    /* border-radius: 6px; */
    /* align-items: center;   */
    /* padding: 12px 30px; */
    /* display: flex; */
    box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #fff;
    /* height: 100%; */
    /* flex: 1; */
}



.button {
    cursor: pointer;
    border-radius: 6px;
    font-size: 18px;
    height: 48px;
    padding: 12px 30px;
    box-sizing: border-box;
}

.form-input-wrapper .email-input {
    flex: 1;
    max-width: 500px;
}

.donate-page {
    margin-bottom: 0px;
}

.donate-page-content-wrapper {
    padding: 5vh 10vw;
}

/* BLOG PAGE -- START*/

.blog-page .articles-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0 10vw;
    margin: 10vh 0;
    gap: 2vw;
}

.blog-page .article-wrapper {
    /* border: 1px solid gray; */
    background: #fbfbfb;
    overflow: hidden;
    border-radius: 10px;
}

.blog-page .article-content-wrapper {
    padding: 5%;
}


.blog-page .article-wrapper .article-cover-image {
    height: 25vh;
    cursor: pointer;
    overflow: hidden;
}

.blog-page .article-wrapper .article-cover-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: 0.3s all;
   
}

.blog-page .article-wrapper .article-cover-image img:hover {
    transform: scale(1.2);
}

.blog-page  .article-wrapper .article-title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5%;
    line-height: 1em;
    height: 5em;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    /* padding: 5%; */
}

.blog-page .article-wrapper .article-highlights {

    
    display: -webkit-box;
    font-size: 16px;
    /* padding: 5%; */
    max-width: 100%;
    min-width: 0px;

    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
   
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5; 
    line-clamp: 5;
    line-height: 1.5em;
    height: 7.5em;
}

.blog-page .article-wrapper .read-more-wrapper {
    margin-top: 2vh;
    font-size: 16px;
    cursor: pointer;
    font-style: italic;    
}


.team-member-description .read-more-wrapper {
    display: none;
}



/* BLOG PAGE -- END*/

.single-article-content-wrapper  {
    margin: 5vh 10vw;
     
}

.single-article-content-wrapper p{
    margin: 2vh 0;
}



.single-article-big-wrapper .message {
    font-size: 40px;
    padding: 3vh 10vw;
}

.single-article-big-wrapper .hero-section {
    height: 55vh;
}


.terms-and-conditions,
.confidentiality-policy {
    
    margin: 110px 10vw 0;
    padding: 5vh 0;
    
}


.cookies-consent-modal-wrapper {
    z-index: 9999;
    position: fixed;
    bottom: 3vh;
    
    left: 0;
    /* width: 40vw; */
    width: 100%;
    padding: 0 2vw;
   
   

    display: flex;
    justify-content: flex-end;
}

.cookies-consent-modal {
    background: #FFFFFF;
    display: flex;
    gap: 16px;
    padding: 16px;
    max-width: 42rem;
    width: 100%;
    font-size: 14px;
    border-radius: 6px;

    box-shadow: 0px 0px 10px #565656
}

.cookies-consent-modal .content {
    flex: 1;

    display: flex;
    flex-direction: column;
    gap: 10px;

    /* padding-left: 10px; */
}

.cookies-consent-modal .content .title {
    font-weight: 700;
}

.buttons-wrapper {
    display: flex;
    gap: 16px;
}

.buttons-wrapper > div {
    cursor: pointer;
    /* padding: 6px 10px; */
}

.buttons-wrapper > div:hover {
    /* font-weight: 500; */
    /* background: #dddddd;
    border-radius: 4px; */
}

.cookies-consent-modal .close-button {
    width: 20px;
    height: 20px;

    cursor: pointer;
}

.mindlab-page .section-title {
    text-align: center;
    font-size: 26px;
    margin-bottom: 18px;
    font-weight: bold;
}

.mindlab-page .video-container {
    margin: 0 10vw 5vh;
    position: relative;
    /* width: 100%;  */
    /* padding-bottom: 56.25%;  */
    margin: 0 10vw 10vh;
    height: 50vh;
    overflow: hidden;


    display: flex;
    align-items: center;
    justify-content: center;
  }
  
.mindlab-page .video-container iframe {
    display: flex;
    align-items: center;
    /* position: absolute; */
    /* top: 0;
    left: 0; */
    width: 70%;
    height: 100%;
    border: 0;
  }


.mindlab-page .research-grid,
.mindlab-page .statistics-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-rows: 150px; */
    gap: 2vh 2vw;
    margin-bottom: 2vh;
}

.research-grid {
    margin: 0 10vw;
}

.mindlab-page .research-item {
    /* border: 1px solid red; */
    
    background: #b99095;
    color: white; 
    color: black;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 150px; */

    padding: 12px 20px;
}

.mindlab-page .research-item:nth-child(2n) {
    background: #3d5b59;
    color: white;
}

.mindlab-page .research-item .research-item-title {
    text-align: center;
    font-weight: 700;
}

.mindlab-page .research-item .research-item-description {
    text-align: center;
}

.mindlab-page .research-item img {
    object-fit: contain;
    width: 32px;
    height: 32px;
    margin-bottom: 10px;
}

.statistics-grid {
    background: #3d5b59;
    color: white;
    margin: 0 10vw;
}

.statistics-grid .statistics-item {
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;
}

.statistics-grid .statistics-item .first-line {
    font-size: 40px;
}

.statistics-grid .statistics-item .second-line {
    font-size: 12px;
}

.mobile-articles-list,
.mobile-books-list {
    display: none;
}

.articles-list-section,
.books-list-section {
    margin: 0 10vw;
    margin-top: 18px;
    padding: 3vh 0 2vh;
    
    text-align: center;
    background: #3d5b59;
    color: white;

    text-align: justify;
}


.articles-list-section .title,
.books-list-section .title{
    font-size: 22px;
    margin-bottom: 8px;
    text-align: center;
}

.articles-list-section .content,
.books-list-section .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: justify;
    /* border: 1px solid red; */
    padding: 0 12vw 3vh;

    /* position: relative; */
}

.articles-list-section .content ul,
.books-list-section .content ul {
    list-style-type: disc;
}


.articles-list-section .see-all-button,
.books-list-section .see-all-button {
    /* position: absolute;
    bottom: 20px; */

    border: 1px solid white;
    padding: 12px 20px;
    margin-top: 20px;

    user-select: none;
}

.articles-list-section .see-all-button:hover,
.books-list-section .see-all-button:hover {
    opacity: 0.8;
}

.articles-list-section .see-all-button:active,
.books-list-section .see-all-button:active {
    opacity: 0.6;
}

.mindlab-articles-content {
    padding: 5vh 15vw;
}


.ongoing-projects-page {
    position: relative;
    margin-bottom: 5vh;
}

.ongoing-projects-page .project-list {
    padding: 3vh 15vw;
    display: grid;
    gap: 10vh;
}

.ongoing-project-first-line {
    display: flex; 
    gap: 20;
}


.ongoing-projects-page .project-list .ongoing-project {
    display: grid;
    /* grid-template-rows: auto 25vh auto auto; */
    gap: 12px;
}

.ongoing-projects-page .project-list .ongoing-project .ongoing-project-title {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}

.ongoing-projects-page .project-list .ongoing-project .ongoing-project-image {
    flex: 1;
    max-width: 400px;
}

.ongoing-projects-page .project-list .ongoing-project .ongoing-project-image img {
    object-fit: contain;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    max-width: 400px;
}

.ongoing-project-cta {
    border: 1px solid rgb(149, 149, 149);
    border-radius: 4px;
    padding: 20px;

    background: #b99095eb;
    color: white;
}


.our-products-page .project-list {
    padding: 3vh 15vw;
    display: grid;
    gap: 10vh;
}


.our-products-page  .project-wrapper {
    display: grid;
    grid-template-rows: auto  25vh auto;
    gap: 12px;
}

.project-wrapper .title{
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}


.project-wrapper  .image-wrapper img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}


.testimonials-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5vh;
    padding: 0 3vw;
}


.testimonial-wrapper {
    display: grid;
    
    background: rgb(246,246,246);
    background: linear-gradient(150deg, rgba(246,246,246,1) 0%, rgba(227,227,227,1) 100%);
    border-radius: 20px;
    
    /* margin: 0 10vw; */
    align-items: center;
    justify-content: center;
    gap: 18px;
    padding: 3vh 3vw;
    /* padding: 5vh 10vw; */
}

.testimonial-wrapper .image-wrapper {
    display: flex;
    justify-content: center;
}

.testimonial-wrapper .image-wrapper img {
    object-fit: cover;
    width: 100px;
    height: 100px;
    border-radius: 50px;
}

.testimonial-wrapper .description {
    font-style: italic;
}

.testimonial-wrapper .name {
    font-weight: bold;
}

.form-35-title {
    margin:  5vh 0;
}

.form-35-description {
    text-align: center;
    font-weight: bold;
   
    
}

.formik-35-form-wrapper {
    display: flex;
    flex-direction: column;
}



.formik-field-error {
    color: #d35151;    
}

.formik-line {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    margin: 12px 0;
}

.formik-form-field {
    flex: 1;
}

.formik-form-field label {
    font-size: 12px;
    font-weight: bold;
}

.formik-form-field input {
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid gray;
    width: 100%;
}

.formik-form-field input[type="checkbox"] {
    min-width: 50px;
    width: 50px;
    height: 20px;
}

.formik-35-form-wrapper button[type="submit"]{
    width: 100px;
    padding: 10px;
    margin-top: 20px;
    cursor: pointer;
}

/* .section-bg-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0px 30px 30px 0;
} */



.our-mission-content {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.redirect-35-wrapper {
    position: relative;
    z-index: 2;
    margin: 5vh 10vw;
}

@media (max-width: 1000px) {

.desktop-page-menu {
        display: none;
    }


    .mobile-page-menu {
        display: block;
        height: 100%;
        text-align: right;
    }

    .mobile-menu-items-wrapper {
        display: flex;
        flex-direction: column;
        background: white;
        position: relative;
        width: 180px;
    }

    .mobile-first-level-wrapper {
        position: absolute;
        width: 100%;
        top: -10;
        left: 0;
        background: white;
        min-height: 100%;      
        transition: 0.5s all ;
    }

    .mobile-page-menu .menu-item {
        margin: 10px 0;
        padding: 16px 10px 16px 30px;
        text-align: left;
    }

    .page-top-menu-wrapper {
        padding: 20px 0 20px 20px;
    }
    

    .who-we-are-page .hero-section,
    .what-we-do-page .hero-section,
    .mindlab-page .hero-section,
    .get-involved-page .hero-section,
    .donate-page .hero-section,
    .blog-page .hero-section {  
        height: 35vh;
    }
    
    .statistics-wrapper {
        display: flex;   
        flex-direction: column;
        gap: 50px;
    }

    .footer-wrapper {
        grid-template-columns: 2fr 1fr;
        grid-template-rows: 1fr 1fr 100px;
    }


    .footer-logos-wrapper {
        display: flex;
        /* flex-direction: column; */
        position: relative;
        align-items: center;
        justify-content: center;
        z-index: 1000;
        grid-column: 1/3;
        grid-row: 1/2; 
    }

    .footer-logos-wrapper > div {
        flex: 1;
    }

  

    .mindcare-icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .lab-icon-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;       
    }

    .lab-icon-wrapper > img {
        margin-top: 10;
        object-fit: cover;
        width: 50%;
    }

    
    .footer-contact-bank-details {
        grid-column: 1/2;
        grid-row: 1/2;


        grid-row: 2/3;
    }

    .footer-contact-wrapper {
        grid-column: 2/3;
        grid-row: 1/2;

        grid-row: 2/3;
    }

    .donate-button-wrapper {
        grid-column: 2/3;
        grid-row: 2/3;

        grid-row: 3/4;

        display: flex;
        align-items: center;
    }
    
    .social-media-wrapper {
        grid-column: 1/2;
        grid-row: 2/3;
       
        grid-row: 3/4;
    }

    .form-wrapper .form-input-wrapper {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .form-input-wrapper .submit-button {
        margin-left: 0;
        margin-top: 10px;
    }

    .form-input-wrapper .email-input {
        width: 100%;
    }
    

    .values-wrapper {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        /* grid-template-rows: 1fr 1fr; */
        align-items: center;
        justify-content: center;
        gap: 24px;
    }

    .value-item {
        grid-column: span 2;
    }

    .value-item.count-4.index-3 {
        grid-column: 3/5;
    }

    .value-item.count-5.index-3 {
        grid-column: 2/4;
    }

    .value-item.count-5.index-4 
    {
        grid-column: 4/6;
    }
    
    .objective-item-wrapper {
        grid-template-columns: 1fr;
        grid-template-rows: 25vh auto auto;
    }

    .objective-item-wrapper.image-last .image,
    .objective-item-wrapper.image-first .image,
    .objective-item-wrapper .image {
        grid-row: 1/2;
        grid-column: 1/2;
    }

    .objective-item-wrapper .image-mask {
        mask-image: none;
    }

    .objective-item-wrapper.image-first .title,
    .objective-item-wrapper .title {
        margin: 2vh 0;
        grid-row: 2/3;
        grid-column: 1/2;
    }

    .objective-item-wrapper.image-first .description,   
    .objective-item-wrapper .description {
        grid-row: 3/4;
        grid-column: 1/2;
    }

  
    
    .ongoing-project-first-line {
        display: flex; 
        flex-direction: column;
    } 

    .ongoing-project-image {
        display: flex;
        align-items: center;
        justify-content: center;
       
    }

    .ongoing-projects-page .project-list .ongoing-project .ongoing-project-image {
        max-width: unset;
    }

    .ongoing-project-image img {
        max-width: 400px;
    }
}

@media (max-width: 900px) {
    /* .desktop-page-menu {
        display: none;
    }


    .mobile-page-menu {
        display: block;
        height: 100%;
        text-align: right;
    }

    .mobile-menu-items-wrapper {
        display: flex;
        flex-direction: column;
        background: white;
        position: relative;
        width: 180px;
    }

    .mobile-first-level-wrapper {
        position: absolute;
        width: 100%;
        top: -10;
        left: 0;
        background: white;
        min-height: 100%;      
        transition: 0.5s all ;
    }

    .mobile-page-menu .menu-item {
        margin: 10px 0;
        padding: 16px 10px 16px 30px;
        text-align: left;
    }

    .page-top-menu-wrapper {
        padding: 20px 0 20px 20px;
    } */


    .blog-page .articles-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 0 10vw;
        margin: 10vh 0;
       
    }

    .desktop-articles-wrapper {
        display: none;
    }

    .mobile-articles-wrapper {
        display: block;
    }

    .article-wrapper {
        margin-right: 30px;
    }


    .mindlab-page .research-grid {
        grid-template-columns: repeat(2, 1fr);
    }
    
}


@media (max-width: 750px) {
  

    .footer-wrapper {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        /* grid-template-rows: 1fr 100px; */
    }


    .footer-logos-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: center;
        justify-content: center;
        z-index: 1000;
        grid-column: 1/2;
        grid-row: 1/2; 
    }


    .lab-icon-wrapper {
        margin-top: 20px;
    }
    
    .footer-contact-bank-details {
        grid-column: 1/2;
        /* grid-row: 1/2; */

        grid-row: 2/3;
    }

    .footer-contact-wrapper {
        grid-column: 1/2;
        grid-row: 2/3;

        grid-row: 4/5;
    }

   
    
    .social-media-wrapper {
        grid-column: 1/2;
        grid-row: 3/4;

        grid-row: 5/6;
       
    }

    .donate-button-wrapper {
        grid-column: 1/2;
        grid-row: 4/5;

        grid-row: 6/7;

        display: flex;
        align-items: center;
    }
    
    .newsletter-form {
        flex-direction: column;
    }

    .newsletter-button-wrapper {
        margin: 0;
        margin-top: 10px;
    }

  

   
    .values-wrapper {
        grid-template-columns: repeat(6, 1fr);
    }


    .value-item {
        grid-column: span 3;
    }

    .value-item.count-4.index-3 {
        grid-column: span 3;
    }

    .value-item.count-5.index-3 {
        grid-column: span 3;
    }

    .value-item.count-5.index-4 
    {
        grid-column: 2/6;
    }
    










    .donate-section,
    .mindlab-section {
        margin:0;
        margin-top: 5vh;
        
        border-radius: 0;
      
    }

    .donate-section .background-gradient,
    .mindlab-section .background-gradient {
        border-radius: 0;
        grid-template-columns: 1fr;
        /* padding: 5vh 15vw; */
        padding: 5vh 0 0;
    }



    .donate-section .section-bg-image,
    .mindlab-section .section-bg-image {
        grid-row: 4/5;
        grid-column: 1/2;
        height: 50vh;
        border-radius: 0;
        box-shadow: none;
        background-position: top;
    }

    .donate-section .title-wrapper,
    .mindlab-section .title-wrapper {
        justify-content: center;
        text-align: center;
        padding: 0 15vw;

        grid-column: 1/2;
        grid-row: 1/2;
    }

    .donate-section .description-wrapper,
    .donate-section .button-wrapper,
    
    .mindlab-section .description-wrapper,
    .mindlab-section .button-wrapper{     
        padding: 0 15vw;
        text-align: center;
        justify-content: center;
    }

    .donate-section .description-wrapper,
    .mindlab-section .description-wrapper {
        grid-column: 1/2;
        grid-row: 2/3;
    }

    .donate-section .button-wrapper,
    .mindlab-section .button-wrapper {
        grid-column: 1/2;
    }


    .donate-section .button-wrapper button,
    .mindlab-section .button-wrapper button {
        padding: 28px;
    }






















    .our-team-section {
        margin: 0;
        border-radius: 0;
      
    }

    .our-team-section .background-gradient {
        border-radius: 0;
        grid-template-columns: 1fr;
        /* padding: 5vh 15vw; */
        padding: 5vh 0 0;
    }



    .our-team-section .section-bg-image {
        grid-row: 4/5;
        grid-column: 1/2;
        height: 50vh;
        border-radius: 0;
        box-shadow: none;
        background-position: top;
    }

    .our-team-section .title-wrapper {
        justify-content: center;
        padding: 0 15vw;

        grid-column: 1/2;
        grid-row: 1/2;
    }

    .our-team-section .description-wrapper,
    .our-team-section .button-wrapper {     
        padding: 0 15vw;
        text-align: center;
        justify-content: center;       
    }

    .our-team-section .description-wrapper {
        grid-column: 1/2;
        grid-row: 2/3;
    }

    .our-team-section .button-wrapper {
        grid-column: 1/2;
        display: flex;
        flex-direction: column;
        align-items: center;
    }


    .our-team-section .button-wrapper button {
        padding: 28px;
    }




        
    .team-member-item {
        display: grid;
        grid-template-columns: 1fr;
        gap: 3vw;
    }


    .team-member-left-column {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, 1fr);
    }

    .image-box-wrapper {
        width: 30vw;
        height: 30vw;
        grid-row: 1/5;
    }

    .name-and-title {
        grid-row: 1/3;
    }


    .team-member-left-column .name-and-title {
        text-align: center;
        margin-top: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .image-mask {
        mask-image: none;
        -webkit-mask-image:none;
    }

    .what-we-do-page .hero-section .message { 
        font-size: 24px;
    }


    /* .right-image-position {
        padding-right: 0;
    }

    .left-image-position {
        padding-left: 0;
    } */

   
    .testimonials-wrapper {
        grid-template-columns: 1fr;
        padding: 0 20vw;
    }


    .formik-line {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin: 0;
        margin-bottom: 12px;
        /* margin: 10px 0; */
    }

}

@media (max-width: 600px) {



    .homepage-page .hero-section .message {
        text-align: center;
    }

    .our-mission-content {
        text-align: center;
    }

    .statistics-section .title-wrapper {
        text-align: center;
    }

    .statistic-item .stats-description {
        text-align: center;
    }

    .hero-section-bg-image {
        background: url(./img/bg1_mobile.webp);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .who-we-are-page .hero-section-bg-image {
        background: url(./img/allteam.webp);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    
    .what-we-do-page .hero-section-bg-image {
        /* background: url(./img/team3.webp); */
        background: url(./img/pawns.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .get-involved-page .hero-section-bg-image {
        background: url(./img/hands2_mobile.webp);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }


 
    .donate-section .section-bg-image,
    .mindlab-section .section-bg-image {
       
        background: url(./img/hands_mobile.webp);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }




    .objective-item-wrapper .bg-image-section.objective-img-1 {
        background: url(./img/o1_alt.webp);
        width: 100%;
        height: 100%;
    
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    
    .objective-item-wrapper .bg-image-section.objective-img-2 {
        background: url(./img/o2_mobile.webp);
        width: 100%;
        height: 100%;
    
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    
    
    .objective-item-wrapper .bg-image-section.objective-img-3 {
        background: url(./img/o3_mobile.webp);
        width: 100%;
        height: 100%;
    
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    
    
    
    .objective-item-wrapper .bg-image-section.objective-img-4 {
        background: url(./img/o4_mobile.webp);
        width: 100%;
        height: 100%;
    
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    
    
    .objective-item-wrapper .bg-image-section.objective-img-5 {
        background: url(./img/o5_mobile.webp);
        width: 100%;
        height: 100%;
    
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    
    
    .objective-item-wrapper .bg-image-section.objective-img-6 {
        background: url(./img/o6_mobile.webp);
        width: 100%;
        height: 100%;
    
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }


    .form-wrapper .image-wrapper {    
        background: url(./img/volunteers_mobile.webp);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    










    .transition-wrapper img {
        transform: scale(1.6);
    }


    .homepage-page .page-title-section {
        font-size: 30px;
        line-height: 30px;
        padding: 10vh 5vw;
        text-align: center;
    }

    .title-wrapper {
        font-size: 30px !important;
    }

    .hero-section .message {
        width: 70%;
        font-size: 22px;
    }



    .values-wrapper {
        grid-template-columns: 1fr;    
    }

    .value-item {
        grid-column: span 1;
        flex-direction: row;
        display: flex;
    }


    .value-item,
    .value-item.count-4.index-3,
    .value-item.count-5.index-3,
    .value-item.count-5.index-4 {
        grid-column: span 1;
    }

   

    .value-icon {
        flex: 1;
        max-width:100px;
        min-width:100px;
        margin-right: 40px;
    }

    .value-text {
        flex: 2;
        text-align: left;
    }















    .articles-section {
        margin: 10vh 5vw;
    }

    .react-multi-carousel-list   {
        padding: 10px;
        margin-left: -10px;
    }

    

    .articles-section .title-wrapper {
        margin: 0 10vw;
        margin-bottom: 5vh;
        text-align: center;
    }

   









    .team-member-left-column {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .image-box-wrapper {
        width: 50vw;
        height: 50vw;
    }







    .form-wrapper {
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: unset;
        /* grid-template-rows: 1fr 5vh; */
        gap: 20px;
    }

    .form-wrapper .image-wrapper {
        grid-column: 1/3;
        grid-row: 1/2;
    }
    
    .form-wrapper .form-cta-wrapper {
        grid-column: 3/6;
        grid-row: 1/2;
    }
    
    .form-wrapper .form-input-wrapper {
        grid-column: 1/6;
        grid-row: 2/3;
        display: flex;
    }
    
    .form-wrapper .form-submit-button-wrapper {
        grid-column: 4/6;
        grid-row: 2/3;
    }

    .form-wrapper input { 
        width: unset;
        height: unset;
        min-width: unset;
        max-width: unset;
    }

   


    .button {
        cursor: pointer;
        border-radius: 6px;
        font-size: 14px;
        height: 40px;
        padding: 8px 18px;
        box-sizing: border-box;
        min-width: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .blog-page .articles-wrapper {
       grid-template-columns: 1fr;
       gap: 5vh 0;
       margin: 3vh 0;
    }

    .blog-page .articles-wrapper .article-title {
        height: unset;
    }


    .blog-page .articles-wrapper .article-wrapper {
        margin: 0;
    }

    .mindlab-page .statistics-grid {
        grid-template-columns: 1fr;
    }

    .statistics-grid .statistics-item {
        grid-column: 1/2;
    }

    .statistics-grid .statistics-item .first-line {
        font-size: 32px;
    }


    .team-member-description {
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .team-member-description .read-more-wrapper {       
        display: block;
        margin: 12px 20px;
        border: 1px solid black;
        padding: 12px 20px;
        border-radius: 10px;       
    }

    .team-member-description.short-version .content {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1em;
        -webkit-line-clamp: 10;
        line-clamp: 10;
        -webkit-box-orient: vertical;
      
        height: 10em;
        max-height: 10em;
        text-align: center;    
    }

    .team-member-description.long-version .content {
        display: block;
        overflow: visible;
        text-overflow: ellipsis;
        line-height: 1em;
        text-align: center;
        /* -webkit-line-clamp: 10; */
        /* line-clamp: 10; */
        /* -webkit-box-orient: vertical; */
      
        /* height: 10em; */
        /* max-height: 10em;         */
    }

    .team-member-description.long-version .read-more-wrapper {
        display: none;
    }

    .who-we-are-page .page-title-section,
    .what-we-do-page .page-title-section,
    .mindlab-page .page-title-section,
    .get-involved-page .page-title-section
    {
        
        text-align: center;
         
    }


    .objective-item-wrapper .paragraph {
      
        text-align: left;
        /* font-size: 14px; */
    }

    .get-involved-content-wrapper {
        grid-template-columns: 1fr;
        gap: 5vh;
    }

    .contact-us-wrapper,
    .volunteer-wrapper {
        padding: 10vw;
       
    }

    .desktop-articles-list,
    .desktop-books-list {
        display: none;
    }

    .mobile-articles-list,
    .mobile-books-list {
        display: block;
    }


    .ongoing-project-first-line {
        display: flex; 
        flex-direction: column;
    } 
}

@media (max-width: 450px) {
    .form-wrapper {
        grid-template-columns: repeat(5, 1fr);
        /* grid-template-rows: 1fr 5vh 5vh; */
        grid-template-rows: unset;
        gap: 20px;
    }

    .form-wrapper .form-input-wrapper { 
        grid-column: 1/6;
    }

    .form-wrapper .form-submit-button-wrapper {
        grid-column: 1/6;
        grid-row: 3/4;
    }


    .mindlab-page .research-grid {
        grid-template-columns: 1fr;
    }

    

    .statistics-grid .statistics-item {
        height: 80px;
    }

   
    .testimonials-wrapper {
        grid-template-columns: 1fr;
        padding: 0 10vw;
    }
}

@media (min-width: 1800px)  {

    .inner-top-menu-wrapper {
        width: 1400px;
    }
    

    .objective-item-wrapper .bg-image-section.objective-img-1 {
        background: url(./img/o1_alt.webp);
        width: 100%;
        height: 100%;
    
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    
    .objective-item-wrapper .bg-image-section.objective-img-2 {
        background: url(./img/o2_2k.webp);
        width: 100%;
        height: 100%;
    
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    
    
    .objective-item-wrapper .bg-image-section.objective-img-3 {
        background: url(./img/o3_2k.webp);
        width: 100%;
        height: 100%;
    
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    
    
    
    .objective-item-wrapper .bg-image-section.objective-img-4 {
        background: url(./img/o4_2k.webp);
        width: 100%;
        height: 100%;
    
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    
    
    .objective-item-wrapper .bg-image-section.objective-img-5 {
        background: url(./img/o5_2k.webp);
        width: 100%;
        height: 100%;
    
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    
    
    .objective-item-wrapper .bg-image-section.objective-img-6 {
        background: url(./img/o6_2k.webp);
        width: 100%;
        height: 100%;
    
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .form-wrapper .image-wrapper {    
        background: url(./img/volunteers_2k.webp);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    













    .hero-section .message,
    .who-we-are-page .hero-section .message,
    .what-we-do-page .hero-section .message,
    .mindlab-page .hero-section .message,
    .blog-page .hero-section .message ,
    .get-involved-page .hero-section .message,
    .donate-page .hero-section .message
     {
        top: 0;
        left: 0;
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background: none;
        /* border: 1px solid red; */
        padding: 0;

        width: 1400px;
    }


    .homepage-page .hero-section .message .inner-wrapper {
        width: 1000px;
        background: linear-gradient(146deg, #d2ddc0b3 0%, #d2ddc0 80%);    
       
       
    }

    .hero-section .message .inner-wrapper {
        background: linear-gradient(146deg, #a6a9a2ad 0%, #4e4b46 85%);
        padding: 5vh 5vw;
        border-radius: 8;
    }

    

    .menu-item {
        font-size: 16px;
        margin: 0 24px;
    }

    .hero-section {
        height: 50vh;
        display: flex;
        justify-content: center;
    }

    .homepage-page .hero-section .message {
        /* width: 100%; */
        width: 1400px;
        /* font-size: 24px; */
    }

    .page-title-section {
        font-size: 38px;
        line-height: 38px;
        margin: 10vh 20vw;
        min-height: 20vh;
        /* width: 100%; */
        display: flex;
        justify-content: center;
    }

    .page-title-section .inner-wrapper {
        width: 1400px;        
    }

    .title-wrapper {
        font-size: 40px;
    }

    .values-section {
        width: 1400px;
    }

    .values-wrapper .value-item .value-icon {
        width: 160px;
        height: 160px;
        border-radius: 80px;
    }

    .values-wrapper .value-item .value-title {
        font-size: 22px;
        font-weight: 500;
        margin: 20px 0;
    }

    .values-wrapper .value-item  .value-description {
        font-size: 20px;
    }

    .our-team-section .title-wrapper,
    .donate-section .title-wrapper,
    .mindlab-section .title-wrapper {
        font-size: 40px;
    }

    .our-team-section .description-wrapper,
    .donate-section .description-wrapper,
    .mindlab-section .description-wrapper {
        font-size: 22px;
        line-height: 32px;
    }

    .our-team-section .button-wrapper div,
    .donate-section .button-wrapper div,
    .mindlab-section .button-wrapper div {
        width: unset;
        padding: 26px 50px;
    }


    .donate-section .section-bg-image,
    .mindlab-section .section-bg-image {
        height: 40vh;
        background: url(./img/hands_2k.webp);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .statistics-section .title-wrapper {
        font-size: 48px;
    }

    .statistics-section .statistic-item {
        font-size: 20px;
    }


    .articles-section .title-wrapper {
        font-size: 48px;
    }

    .article-content .article-title {
        font-size: 28px;
    }

    .article-content .article-description {
        font-size: 18px;
        line-height: 26px;
        max-height: 10vh;
    }

    .article-content .article-see-more {
        font-size: 20px;
        margin-top: 32px;
    }

    /* .newsletter-section {
        margin: 10vh 15vw;
    } */

    .newsletter-section .title-wrapper {
        font-size: 48px;
        margin-bottom: 3vh;
    }

    .footer-wrapper {
        padding: 10vh 10vw;
        font-size: 20px;
        line-height: 32px;
    }


    .team-members-wrapper .team-member-item {
        font-size: 24px;
        line-height: 38px;
    }

    .what-we-do-page .objective-item-wrapper .title {
        font-size: 30px;
    }

    .what-we-do-page .objective-item-wrapper .paragraph {
        font-size: 22px;
    }


  

    .get-involved-page .description {
        font-size: 20px;
    }

    .get-involved-page .form-wrapper {
        height: 20vh;
    }

    .get-involved-page .form-cta-wrapper {
        font-size: 28px;
    }


    .our-mission-content {
        width: 1400px;
    }

    .who-we-are-page .hero-section {
        /* width: 1400px; */
        display: flex;
    }
}


.f230ro-lansare {
    width: 150px;
    padding: 10px;
    margin-top: 20px;
}